import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  Input,
} from "@mui/material";

// Pre-defined options
const STATUS_CHOICES = [
  { value: "action_required", label: "Action Required" },
  { value: "completed", label: "Completed" },
  { value: "in_progress", label: "In Progress" },
];

const CATEGORY_CHOICES = [
  { value: "REDD+", label: "REDD+" },
  { value: "ARR", label: "ARR" },
  { value: "IFM", label: "IFM" },
];

const PROJECT_PROGRESS_CHOICES = [
  { value: "Participatory Appraisal", label: "Participatory Appraisal" },
  { value: "Stakeholder Consultation", label: "Stakeholder Consultation" },
  { value: "Contract Gathering", label: "Contract Gathering" },
  { value: "ID of Beneficiaries", label: "ID of Beneficiaries" },
  { value: "Feasibility Assessment", label: "Feasibility Assessment" },
  { value: "Pre-Financing", label: "Pre-Financing" },
];

const EditProject = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [projectData, setProjectData] = useState({
    name: "",
    country: "",
    category: "",
    status: "",
    project_progress: "",
    area_ha: 0,
    net_emission_reduction: 0.0,
    latitude: 0.0,
    longitude: 0.0,
    organization_name: "",
  });
  const [shapefileName, setShapefileName] = useState("");
  const [selectedShapefile, setSelectedShapefile] = useState(null);
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/customers/projects/${projectId}/`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
        setProjectData(response.data);
        // If shapefile URL exists, set the file name
        if (response.data.shapefile) {
          const urlParts = response.data.shapefile.split("/");
          setShapefileName(urlParts[urlParts.length - 1]);
        }
      } catch (error) {
        console.error("Error fetching project data:", error);
      }
    };

    fetchProjectData();
  }, [projectId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProjectData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (event) => {
    setSelectedShapefile(event.target.files[0]);
    setShapefileName(event.target.files[0].name);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    for (const key in projectData) {
      if (projectData.hasOwnProperty(key) && key !== "shapefile") {
        formData.append(key, projectData[key]);
      }
    }
    if (selectedShapefile) {
      // This checks and appends the new file only if a new shapefile has been chosen
      formData.append("shapefile", selectedShapefile);
    }

    const token = localStorage.getItem("token");
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/api/customers/projects/${projectId}/edit/`,
        formData,
        {
          headers: {
            Authorization: `Token ${token}`, // Using 'Token' prefix now
            "Content-Type": "multipart/form-data",
          },
        }
      );
      alert("Project updated successfully");
      navigate(`/project-details/${projectId}`);
    } catch (error) {
      console.error(
        "Error updating project:",
        error.response ? error.response.data : "Unknown error"
      );
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Edit Project
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Name"
          name="name"
          value={projectData.name}
          onChange={handleInputChange}
          margin="normal"
        />

        {/* Non-editable fields can be displayed as informational text or in a disabled TextField if needed */}
        <FormControl fullWidth sx={{ mt: 1, mb: 1, width: "100%" }}>
          <TextField
            label="Organization Name"
            name="organization_name"
            fullWidth
            variant="outlined"
            value={projectData.organization_name || ""}
            disabled
          />
        </FormControl>

        <TextField
          fullWidth
          label="Country"
          name="country"
          value={projectData.country}
          onChange={handleInputChange}
          margin="normal"
        />

        <FormControl
          fullWidth
          margin="dense"
          variant="outlined"
          sx={{ mt: 2, mb: 2 }}
        >
          <InputLabel>Category</InputLabel>
          <Select
            name="category"
            value={projectData.category || ""}
            label="Category"
            onChange={handleInputChange}
          >
            {CATEGORY_CHOICES.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          fullWidth
          margin="dense"
          variant="outlined"
          sx={{ mt: 2, mb: 2 }}
        >
          <InputLabel>Status</InputLabel>
          <Select
            name="status"
            value={projectData.status || ""}
            label="Status"
            onChange={handleInputChange}
          >
            {STATUS_CHOICES.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          fullWidth
          margin="dense"
          variant="outlined"
          sx={{ mt: 2, mb: 2 }}
        >
          <InputLabel>Project Progress</InputLabel>
          <Select
            name="project_progress"
            value={projectData.project_progress || ""}
            label="Project Progress"
            onChange={handleInputChange}
          >
            {PROJECT_PROGRESS_CHOICES.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          label="Country"
          name="country"
          value={projectData.country}
          onChange={handleInputChange}
          margin="normal"
        />

        <TextField
          fullWidth
          label="Area (ha)"
          name="area_ha"
          type="number"
          value={projectData.area_ha}
          onChange={handleInputChange}
          margin="normal"
        />

        <TextField
          fullWidth
          label="Net Emission Reduction"
          name="net_emission_reduction"
          type="number"
          value={projectData.net_emission_reduction}
          onChange={handleInputChange}
          margin="normal"
        />

        <TextField
          fullWidth
          label="Latitude"
          name="latitude"
          type="number"
          value={projectData.latitude}
          onChange={handleInputChange}
          margin="normal"
        />

        <TextField
          fullWidth
          label="Longitude"
          name="longitude"
          type="number"
          value={projectData.longitude}
          onChange={handleInputChange}
          margin="normal"
        />

        {/* Display the shapefile name if available */}
        {shapefileName ? (
          <Typography>Current shapefile: {shapefileName}</Typography>
        ) : (
          <Typography>No shapefile uploaded</Typography>
        )}
        <Input type="file" onChange={handleFileChange} />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
        >
          Update Project
        </Button>
      </form>
    </Container>
  );
};

export default EditProject;
