// TestMap.js

import React from "react";
import { MapContainer, TileLayer, LayersControl } from "react-leaflet";
import RasterOverlay from "./RasterOverlay"; // Adjust the import path as necessary

const TestMap = () => {
  // Define a default center and zoom level that corresponds to the area you expect to see tiles for
  const center = [-3.6340124173943753, 30.679068805612996]; // Example center, adjust as necessary
  const zoom = 16; // Example zoom, adjust as necessary

  //URL pattern for your tiles, adjust as necessary
  //   const tileUrlPattern =
  //     "http://127.0.0.1:8000/static/raster/js/output/{z}/{x}/{y}.png";

  const tileUrlPattern =
    "http://127.0.0.1:8000/static/raster/js/output/{z}/{x}/{y}.png";

  return (
    <MapContainer
      center={center}
      zoom={zoom}
      style={{ height: "600px", width: "100%" }}
    >
      <LayersControl position="topright">
        <LayersControl.BaseLayer name="OpenStreetMap" checked>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        </LayersControl.BaseLayer>
        <LayersControl.Overlay name="Raster Overlay" checked>
          <RasterOverlay tileUrl={tileUrlPattern} />
        </LayersControl.Overlay>
      </LayersControl>
    </MapContainer>
  );
};

export default TestMap;
