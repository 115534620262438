import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Container,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";

// Assuming logoUrl is imported correctly from your assets
import logoUrl from "../Assets/Mapicons/WordMark_color_transparentbg.png";

function Header() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const isAuthenticated = !!localStorage.getItem("token"); // Adjust according to your auth logic

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    // Implement logout logic here
    localStorage.removeItem("token");
    navigate("/login");
    handleClose();
  };
  const handleHome = () => {
    navigate("/");
    handleClose();
  };

  return (
    <Container maxWidth="lg">
      <Toolbar
        disableGutters
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "5px",
        }}
      >
        <img
          src={logoUrl}
          alt="Logo"
          style={{ height: "100px", width: "200px" }}
        />
        <Box sx={{ flexGrow: 1 }} />
        {isAuthenticated ? (
          <>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <Avatar sx={{ bgcolor: "#70C29C" }} alt="User Avatar" />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                // vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
            >
              {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
              <MenuItem onClick={handleHome}>Home</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </>
        ) : (
          <></>
        )}
      </Toolbar>
    </Container>
  );
}

export default Header;
