import React, { useEffect, useRef } from "react";
import L from "leaflet";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // For redirecting to login
import "leaflet/dist/leaflet.css";

const GEEGlobalForestChange = ({ projectId }) => {
  const mapRef = useRef(null); // Reference to store the Leaflet map
  const navigate = useNavigate(); // React Router hook for navigation

  // Initialize the map
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token not found. Redirect to the login page.");
      navigate("/login"); // Redirect to login page or handle accordingly
      return;
    }

    mapRef.current = L.map("geeMap", {
      center: [37.5, -122.2], // Default center of the map
      zoom: 10, // Default zoom level
    });

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution: "© OpenStreetMap contributors",
    }).addTo(mapRef.current);

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
      }
    };
  }, [navigate]);

  // Fetch and display the GEE data layer
  useEffect(() => {
    const fetchGEETiles = async () => {
      const projectId = 1;
      const apiUrl = `http://127.0.0.1:8000/api/customers/projects/${projectId}/gee-deforestation-data/`;
      const token = localStorage.getItem("token"); // Retrieve the token from localStorage

      if (!token) {
        console.error("Token not found. Skipping GEE data fetch.");
        return;
      }

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Token ${token}`, // Use Bearer token if appropriate
          },
        });
        if (response.data && response.data.tile_url) {
          const geeLayer = L.tileLayer(response.data.tile_url, {
            attribution: "Google Earth Engine",
          }).addTo(mapRef.current);
          geeLayer.bringToFront();
        }
      } catch (error) {
        console.error("Error fetching GEE data:", error);
      }
    };

    if (mapRef.current) {
      fetchGEETiles();
    }
  }, [projectId]);

  return <div id="geeMap" style={{ width: "100%", height: "500px" }}></div>;
};

export default GEEGlobalForestChange;
