import React, { useState, useEffect } from "react";
import axios from "axios";
import { Typography, Box, CircularProgress } from "@mui/material";

const TextGenerationComponent = ({ projectId, selectedId }) => {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const token = localStorage.getItem("token"); // Fetch the token from localStorage

      if (!token) {
        setError("Authentication token not found.");
        setLoading(false);
        return; // Exit if no token is available
      }
      console.log("Project ID:", projectId); // Check what projectId is received
      console.log("Selected ID:", selectedId); // Check what selectedId is received

      try {
        const response = await axios.get(
          `http://127.0.0.1:8000/api/customers/projects/${projectId}/tiff/${selectedId}/text-generation/`,
          {
            headers: { Authorization: `Token ${token}` }, // Use token for API request
          }
        );
        setData(response.data.message);
        setError("");
      } catch (err) {
        setError("Failed to fetch data");
        setData("");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [projectId, selectedId]);

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h5" gutterBottom>
        Text Generation Analysis
      </Typography>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <Typography style={{ whiteSpace: "pre-line" }}>{data}</Typography>
      )}
    </Box>
  );
};

export default TextGenerationComponent;
