import React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
} from "@mui/material";
import { SparkLineChart } from "@mui/x-charts";

// Fake data for the sparkline
const new_data = [5, 10, 5, 20, 8, 15, 10, 20, 10, 5, 15, 20];
const last_data = [8, 10, 8, 20, 8, 22, 10, 20, 10, 5, 15, 20];

// Calculate variations
const variations = new_data.map((newVal, index) => newVal - last_data[index]);

function Dashboard() {
  return (
    <>
      <Box sx={{ display: "flex", minHeight: "100vh", bgcolor: "#FFF6EB" }}>
        {/* Sidebar */}
        <AppBar position="static" sx={{ width: 256, bgcolor: "#70C29C" }}>
          <Toolbar>
            <Typography
              variant="h6"
              noWrap
              sx={{ color: "#0E3B43", fontWeight: "bold" }}
            >
              HR Economist
            </Typography>
          </Toolbar>
          <Divider sx={{ bgcolor: "#FFF6EB" }} />

          <List>
            {[
              "Highlights",
              "Labour Force",
              "Job Vacancies",
              "Attraction and Recruitment",
              "Working Conditions",
            ].map((text, index) => (
              <ListItem
                button
                key={text}
                sx={{
                  "&:hover": {
                    bgcolor: "#F5CB5C",
                  },
                }}
              >
                <ListItemText
                  primary={text}
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "2.1rem", // Adjust font size as needed
                  }}
                />
              </ListItem>
            ))}
          </List>
        </AppBar>

        {/* Main Content */}
        <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
          {/* Dashboard Items */}
          <Grid container spacing={3}>
            {/* Total Head Count */}
            <Grid item xs={12} sm={6} md={3}>
              <Card style={{ width: "275px" }}>
                <CardContent>
                  <Typography
                    variant="body1"
                    display="block"
                    gutterBottom
                    fontWeight="bold"
                  >
                    Total Head Count
                  </Typography>
                  <Typography variant="h4" component="div" gutterBottom>
                    20,972,200
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="success.main"
                  >
                    ▲0.5%
                  </Typography>
                  <Typography variant="caption" display="block" gutterBottom>
                    Last month variation
                  </Typography>
                  <div style={{ width: "275px" }}>
                    <SparkLineChart data={variations} height={20} />
                    <SparkLineChart data={variations} height={20} />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            {/* Full-Time Employment Rate */}
            <Grid item xs={12} sm={6} md={3}>
              <Card style={{ width: "275px" }}>
                <CardContent>
                  <Typography
                    variant="body1"
                    display="block"
                    gutterBottom
                    fontWeight="bold"
                  >
                    Total Growth
                  </Typography>
                  <Typography variant="h4" component="div" gutterBottom>
                    76.8%
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="error.main"
                  >
                    ▼0.0%
                  </Typography>
                  <Typography variant="caption" display="block" gutterBottom>
                    Last month variation
                  </Typography>
                  <div style={{ width: "275px" }}>
                    <SparkLineChart data={variations} height={20} />
                    <SparkLineChart data={variations} height={20} />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            {/* Part-Time Employment Rate */}
            <Grid item xs={12} sm={6} md={3}>
              <Card style={{ width: "275px" }}>
                <CardContent>
                  <Typography
                    variant="body1"
                    display="block"
                    gutterBottom
                    fontWeight="bold"
                  >
                    Total Increment
                  </Typography>
                  <Typography variant="h4" component="div" gutterBottom>
                    17.8%
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="error.main"
                  >
                    ▼0.1%
                  </Typography>
                  <Typography variant="caption" display="block" gutterBottom>
                    Last month variation
                  </Typography>
                  <div style={{ width: "275px" }}>
                    <SparkLineChart data={variations} height={20} />
                    <SparkLineChart data={variations} height={20} />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            {/* Total Revenue Count */}
            <Grid item xs={12} sm={6} md={3}>
              <Card style={{ width: "275px" }}>
                <CardContent>
                  <Typography
                    variant="body1"
                    display="block"
                    gutterBottom
                    fontWeight="bold"
                  >
                    Total Revenue
                  </Typography>
                  <Typography variant="h4" component="div" gutterBottom>
                    £ 55,00,00
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="success.main"
                  >
                    ▲0.5%
                  </Typography>
                  <Typography variant="caption" display="block" gutterBottom>
                    Last month variation
                  </Typography>
                  <div style={{ width: "275px" }}>
                    <SparkLineChart data={variations} height={20} />
                    <SparkLineChart data={variations} height={20} />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            {/* By Industry */}
            <Grid item xs={12} sm={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    By Industry
                  </Typography>
                  {/* Repeat for each industry */}
                  <Typography variant="body1">
                    Wholesale and retail trade - 3.0M
                  </Typography>
                  <Typography variant="body1">
                    Wholesale and retail trade - 3.0M
                  </Typography>
                  {/* ... other industries */}
                </CardContent>
              </Card>
            </Grid>

            {/* By Gender */}
            <Grid item xs={12} sm={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    By Gender
                  </Typography>
                  <Typography variant="body1">Males - 11.0M</Typography>
                  <Typography variant="body1">Females - 10.0M</Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* By Age Group - first part */}
            <Grid item xs={6}>
              <Card
                sx={{
                  bgcolor: "linear-gradient(to right, #f5f5f5, #fff)",
                  border: "1px solid #eee",
                  borderRadius: 4,
                  boxShadow:
                    "0px 2px 4px rgba(0, 0, 0, 0.1)" /* Add subtle shadow */,
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center" /* Vertically center content */,
                    alignItems: "center",
                    p: 2,
                    color: "#5C6BC0",
                  }}
                >
                  <Typography variant="h5" gutterBottom fontWeight="bold">
                    Age Group
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    15 to 24 years -{" "}
                    <strong sx={{ fontSize: 18, fontWeight: "bold" }}>
                      2.8M
                    </strong>
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    25 to 54 years -{" "}
                    <strong sx={{ fontSize: 18, fontWeight: "bold" }}>
                      13.6M
                    </strong>
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    55 years and over -{" "}
                    <strong sx={{ fontSize: 18, fontWeight: "bold" }}>
                      4.6M
                    </strong>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* By Age Group - Second  part */}
            <Grid item xs={6}>
              <Card
                sx={{
                  bgcolor: "linear-gradient(to right, #f5f5f5, #fff)",
                  border: "1px solid #eee",
                  borderRadius: 4,
                  boxShadow:
                    "0px 2px 4px rgba(0, 0, 0, 0.1)" /* Add subtle shadow */,
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center" /* Vertically center content */,
                    alignItems: "center",
                    p: 2,
                    color: "#5C6BC0",
                  }}
                >
                  <Typography variant="h5" gutterBottom fontWeight="bold">
                    By Age Group
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    15 to 24 years -{" "}
                    <strong sx={{ fontSize: 18, fontWeight: "bold" }}>
                      2.8M
                    </strong>
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    25 to 54 years -{" "}
                    <strong sx={{ fontSize: 18, fontWeight: "bold" }}>
                      13.6M
                    </strong>
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    55 years and over -{" "}
                    <strong sx={{ fontSize: 18, fontWeight: "bold" }}>
                      4.6M
                    </strong>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Use Card component for each item */}
            <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
              {/* Unemployment Rate */}
              <Card style={{ width: "300px" }}>
                <CardContent
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h4" component="div" gutterBottom>
                    5.43%
                  </Typography>
                  <Box m={1} />{" "}
                  {/* Add a margin of 8px (you can adjust the value as needed) */}
                  <Typography
                    variant="body2"
                    component="div"
                    color="success.main"
                  >
                    ▲0.1%
                  </Typography>
                  <div style={{ width: "450px" }}>
                    <SparkLineChart data={new_data} height={50} />
                    <SparkLineChart data={last_data} height={50} />
                  </div>
                </CardContent>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  style={{ textAlign: "center" }}
                  fontWeight="bold"
                >
                  Last month variation
                </Typography>
              </Card>
            </Box>

            {/* Use Card component for each item */}
            <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
              {/* Unemployment Rate */}
              <Card style={{ width: "300px" }}>
                <CardContent
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h4" component="div" gutterBottom>
                    5.43%
                  </Typography>
                  <Box m={1} />{" "}
                  {/* Add a margin of 8px (you can adjust the value as needed) */}
                  <Typography
                    variant="body2"
                    component="div"
                    color="success.main"
                  >
                    ▲0.1%
                  </Typography>
                  <div style={{ width: "450px" }}>
                    <SparkLineChart data={new_data} height={50} />
                    <SparkLineChart data={last_data} height={50} />
                  </div>
                </CardContent>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  style={{ textAlign: "center" }}
                  fontWeight="bold"
                >
                  Last month variation
                </Typography>
              </Card>
            </Box>

            {/* Use Card component for each item */}
            <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
              {/* Unemployment Rate */}
              <Card style={{ width: "300px" }}>
                <CardContent
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h4" component="div" gutterBottom>
                    5.43%
                  </Typography>
                  <Box m={1} />{" "}
                  {/* Add a margin of 8px (you can adjust the value as needed) */}
                  <Typography
                    variant="body2"
                    component="div"
                    color="success.main"
                  >
                    ▲0.1%
                  </Typography>
                  <div style={{ width: "450px" }}>
                    <SparkLineChart data={new_data} height={50} />
                    <SparkLineChart data={last_data} height={50} />
                  </div>
                </CardContent>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  style={{ textAlign: "center" }}
                  fontWeight="bold"
                >
                  Last month variation
                </Typography>
              </Card>
            </Box>

            {/* Use Card component for each item */}
            <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
              {/* Unemployment Rate */}
              <Card style={{ width: "300px" }}>
                <CardContent
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h4" component="div" gutterBottom>
                    5.43%
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="success.main"
                  >
                    ▲0.1%
                  </Typography>

                  <div style={{ width: "450px" }}>
                    <SparkLineChart data={new_data} height={50} />
                    <SparkLineChart data={last_data} height={50} />
                  </div>
                </CardContent>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  style={{ textAlign: "center" }}
                  fontWeight="bold"
                >
                  Last month variation
                </Typography>
              </Card>
            </Box>

            {/* Use Card component for each item */}
            <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
              <Card style={{ width: "300px" }}>
                <CardContent style={{ textAlign: "center" }}>
                  <Typography variant="h4" component="div" gutterBottom>
                    5.43%
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="success.main"
                  >
                    ▲0.1%
                  </Typography>

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <SparkLineChart data={new_data} height={50} />
                  </div>
                  <Typography
                    variant="body1"
                    display="block"
                    gutterBottom
                    fontWeight="bold"
                  >
                    Total Cost Change
                  </Typography>
                </CardContent>
              </Card>
            </Box>

            {/* Use Card component for each item */}
            <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
              <Card style={{ width: "300px" }}>
                <CardContent style={{ textAlign: "center" }}>
                  <Typography variant="h4" component="div" gutterBottom>
                    5.43%
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="success.main"
                  >
                    ▲0.8%
                  </Typography>

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <SparkLineChart data={new_data} height={50} />
                  </div>
                  <Typography
                    variant="body1"
                    display="block"
                    gutterBottom
                    fontWeight="bold"
                  >
                    Bill Change
                  </Typography>
                </CardContent>
              </Card>
            </Box>

            {/* Use Card component for each item */}
            <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
              <Card style={{ width: "300px" }}>
                <CardContent style={{ textAlign: "center" }}>
                  <Typography variant="h4" component="div" gutterBottom>
                    5.43%
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="success.main"
                  >
                    ▲0.1%
                  </Typography>

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <SparkLineChart data={new_data} height={50} />
                  </div>
                  <Typography
                    variant="body1"
                    display="block"
                    gutterBottom
                    fontWeight="bold"
                  >
                    Energy Consumption
                  </Typography>
                </CardContent>
              </Card>
            </Box>

            {/* Use Card component for each item */}
            <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
              <Card style={{ width: "300px" }}>
                <CardContent style={{ textAlign: "center" }}>
                  <Typography variant="h4" component="div" gutterBottom>
                    2300 kwh
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="success.main"
                  >
                    ▲0.1%
                  </Typography>

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <SparkLineChart data={new_data} height={50} />
                  </div>
                  <Typography
                    variant="body1"
                    display="block"
                    gutterBottom
                    fontWeight="bold"
                  >
                    Flat1 Energy Consumption
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default Dashboard;
