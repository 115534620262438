import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "../Assets/Css/CommonStyles.css";
import "../Assets/Css/ProjectDetails.css";

import L from "leaflet";
import LocationIcon from "../Assets/Mapicons/icons8-location-64.png";
import CircularProgress from "@mui/material/CircularProgress";
import ProjectInformation from "../Analysis/ProjectInformation";
import ProjectHeader from "./ProjectHeader";
import ProjectMarker from "./ProjectMarker";
import CreateCircleLayer from "../Analysis/CreateCircleLayer";
import GEELandClassificationMap10m from "../Analysis/GEELandClassificationMap10m";

import {
  customIcon,
  renderCustomMarker,
  fitMapToGeoJson,
  getChipColor,
  getStatusColor,
  calculateBounds,
} from "./Utils";

import {
  MapContainer,
  TileLayer,
  LayersControl,
  GeoJSON,
  ImageOverlay,
  useMapEvents,
} from "react-leaflet";

import { Button, Box } from "@mui/material";

const pointIcon = new L.Icon({
  iconUrl: LocationIcon,
  iconSize: [40, 40], // Size of the icon
  iconAnchor: [20, 40], // Point of the icon which will correspond to marker's location
  popupAnchor: [0, -40], // Point from which the popup should open relative to the iconAnchor
});

const ProjectDetails = () => {
  const [project, setProject] = useState(null);
  const [shape_and_kml_to_geojsonData, setShapeKmlGeojsonData] = useState(null);
  const [mapCenter, setMapCenter] = useState([0, 0]);
  const [circleCenter, setCircleCenter] = useState(null);
  const [allGeoJson, setAllGeoJson] = useState([]);
  const [rasterLayers, setRasterLayers] = useState([]);
  const [mapLoaded, setMapLoaded] = useState(false);
  //const [imageOverlayBounds, setImageOverlayBounds] = useState(null);

  const mapRef = useRef(null);
  const navigate = useNavigate();

  const { projectId } = useParams();

  // Retrieve the token from localStorage
  const token = localStorage.getItem("token");

  const handleNavigate = () => {
    navigate(`/projects/${projectId}/edit/`);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      // Redirect to login or handle the lack of token
      return;
    }

    const fetchData = async () => {
      try {
        // Fetch project details
        const projectResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/customers/projects/${projectId}/`,
          {
            headers: { Authorization: `Token ${token}` },
          }
        );
        if (projectResponse.status < 200 || projectResponse.status >= 300) {
          throw new Error("Error fetching project details");
        }

        const projectData = projectResponse.data;
        setProject(projectData);

        // Set the circle center based on project's latitude and longitude
        setCircleCenter([projectData.latitude, projectData.longitude]);

        // Fetch Shape and KML file data
        const shape_and_kml_to_geojsonResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/customers/projects/${projectId}/fetch_file`
        );

        const shape_and_kml_to_geojsonData =
          shape_and_kml_to_geojsonResponse.data.data;
        setShapeKmlGeojsonData(shape_and_kml_to_geojsonData);
        console.log("GeoJSON data:", shape_and_kml_to_geojsonData);

        // Handling Raster Data
        if (projectData.raster_data && projectData.raster_data.length > 0) {
          const newRasterLayers = projectData.raster_data.map((raster) => ({
            analysisName: raster.analysis_name,
            url: raster.raster_file,
            bounds: calculateBounds(shape_and_kml_to_geojsonData), // Assuming you have a method to calculate bounds
          }));
          // Update state with new raster layers
          setRasterLayers(newRasterLayers);
        }
        //const imageBounds = calculateBounds(shape_and_kml_to_geojsonData);
        //setImageOverlayBounds(imageBounds); // Store bounds for use with the image overlay

        // Handling GeoJSON Data
        if (projectData.geojson_data && projectData.geojson_data.length > 0) {
          const allGeoJsonData = [];
          for (const geoJsonItem of projectData.geojson_data) {
            const geoJsonFileUrl = geoJsonItem.geojson_file;
            const geoJsonDataResponse = await fetch(geoJsonFileUrl);
            const data = await geoJsonDataResponse.json();
            allGeoJsonData.push({
              geoJson: data, // The actual GeoJSON data
              analysisName: geoJsonItem.analysis_name, // The analysis name
            });
          }
          setAllGeoJson(allGeoJsonData);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, [projectId]);

  // This section navigate to the Leaflet page
  const handleleafletNavigate = () => {
    // Assuming project.latitude and project.longitude contain your coordinates
    if (project) {
      navigate(`/leafletmap/${projectId}/${token}`, {
        state: { latitude: project.latitude, longitude: project.longitude },
      });
    }
  };

  const handleleafletsidebarNavigate = () => {
    // Assuming project.latitude and project.longitude contain your coordinates
    if (project) {
      navigate(`/leafletmap-sidebar/${projectId}/${token}`, {
        state: { latitude: project.latitude, longitude: project.longitude },
      });
    }
  };

  // Map-related logic, assuming Leaflet and react-leaflet usage --> Cick on Map and yo will get the cordinates
  const MapClickHandler = ({ onClick }) => {
    useMapEvents({
      click: onClick,
    });
    return null;
  };

  // Cick on Map and yo will get the cordinates
  const handleMapClick = (latlng) => {
    console.log(latlng); // Log or use these coordinates as needed
    // Assuming setCoordinates is defined to handle state for clicked coordinates
  };

  // Separate useEffect for handling map fitting
  useEffect(() => {
    if (shape_and_kml_to_geojsonData && mapLoaded) {
      fitMapToGeoJson(
        mapRef.current.leafletElement,
        shape_and_kml_to_geojsonData
      );
    }
  }, [shape_and_kml_to_geojsonData, mapLoaded]); // Dependencies on GeoJSON data and map loaded state

  // Update mapCenter when the project data changes
  useEffect(() => {
    if (project) {
      setMapCenter([project.latitude, project.longitude]);
    }
  }, [project]);

  if (!project) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="project-details-container">
      {/* Call ProjectHeaders.js component*/}
      <ProjectHeader
        project={project}
        projectId={projectId}
        handleNavigate={handleNavigate}
        getChipColor={getChipColor}
        getStatusColor={getStatusColor}
      />

      {/* Extract and populate shape_and_kml_to_geojsonData */}
      <div>
        {shape_and_kml_to_geojsonData && (
          <MapContainer
            center={mapCenter}
            zoom={14}
            style={{ height: "600px", width: "100%" }}
            whenCreated={(mapInstance) => {
              mapRef.current = mapInstance;
              setMapLoaded(true); // Mark the map as loaded
            }}
          >
            {/* First Layer */}
            <LayersControl position="topright">
              <LayersControl.BaseLayer name="OpenStreetMap">
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              </LayersControl.BaseLayer>

              <LayersControl.BaseLayer checked name="Esri World Imagery">
                <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
              </LayersControl.BaseLayer>

              <LayersControl.BaseLayer name="Topographic">
                <TileLayer url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png" />
              </LayersControl.BaseLayer>

              {/* Add the GeoJSon Data Layer*/}
              {allGeoJson &&
                allGeoJson.map((item, index) => (
                  <LayersControl.Overlay
                    name={item.analysisName || `GeoJSON Data ${index + 1}`} // Use analysisName if available
                    checked
                    key={index}
                  >
                    <GeoJSON
                      data={item.geoJson} // Use the geoJson data
                      pointToLayer={renderCustomMarker} // Use renderCustomMarker from Utils.js
                    />
                  </LayersControl.Overlay>
                ))}

              {/* LayersControl and other map layers */}
              <LayersControl.Overlay name="Project Boundary" checked>
                console.log(shape_and_kml_to_geojsonData)
                <GeoJSON
                  data={shape_and_kml_to_geojsonData}
                  style={{ color: "#3388ff" }} // Example style, adjust as needed
                  pointToLayer={(feature, latlng) => {
                    // This will be called for each Point feature in the GeoJSON
                    return L.marker(latlng, { icon: pointIcon });
                  }}
                />
              </LayersControl.Overlay>

              {/* Adding GEELandClassificationMap10m as a layer */}
              {/* <LayersControl.Overlay name="GEE Land Classification" checked>
                {shape_and_kml_to_geojsonData && (
                  <GEELandClassificationMap10m
                    mapRef={mapRef}
                    projectId={projectId}
                    geoJson={shape_and_kml_to_geojsonData}
                  />
                )}
              </LayersControl.Overlay> */}

              {/* Create Circles Layers Radius Circle (1km, 5km, 50km)*/}
              <CreateCircleLayer circleCenter={circleCenter} />

              {/* Add the Raster Data as an ImageOverlay */}
              <LayersControl.BaseLayer name="Raster">
                {rasterLayers.map(
                  (layer, index) =>
                    layer.url &&
                    layer.bounds && (
                      <LayersControl.BaseLayer
                        name={layer.analysisName}
                        key={index}
                      >
                        <ImageOverlay
                          url={layer.url}
                          bounds={layer.bounds} // Use bounds from each layer in rasterLayers
                        />
                      </LayersControl.BaseLayer>
                    )
                )}
              </LayersControl.BaseLayer>

              {/* Cick on Map and yo will get the cordinates */}
              <MapClickHandler onClick={handleMapClick} />

              {/* Here we use the ProjectMarker component */}
              <ProjectMarker project={project} customIcon={customIcon} />
            </LayersControl>
          </MapContainer>
        )}

        {/* Create a button to navigate to Analyis Detail Page*/}
        {/* <Box sx={{ display: "flex", flexDirection: "column", height: "0vh" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              p: 1,
            }}
          >
            {/* Right-aligned button area */}
        {/* <Button
              onClick={handleleafletNavigate}
              sx={{
                mr: 0,
                fontSize: 18,
                fontWeight: "bold",
                padding: "2px 10px",
                borderRadius: 4,
                background: "linear-gradient(to right, #48C9B0, #61dafb)", // Gradient colors
              }}
            >
              Detail Analysis
            </Button>
          </Box>
        // </Box> 

        {/* Create a button to navigate to Analyis Detail Page*/}
        <Box sx={{ display: "flex", flexDirection: "column", height: "0vh" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              p: 1,
            }}
          >
            {/* Right-aligned button area */}
            <Button
              onClick={handleleafletsidebarNavigate}
              sx={{
                mr: 0,
                fontSize: 18,
                fontWeight: "bold",
                padding: "2px 10px",
                borderRadius: 4,
                background: "linear-gradient(to right, #48C9B0, #61dafb)", // Gradient colors
              }}
            >
              Detailed Analysis
            </Button>
          </Box>
        </Box>
      </div>
      <br />

      <div>
        {/* Render the `DisplayProjectCarbon` component and pass the necessary props */}
        <ProjectInformation projectId={projectId} token={token} />
      </div>
      <br />

      <div>
        {/* {shape_and_kml_to_geojsonData && (
      <GEELandClassificationMap10m
        projectId={projectId}
        geoJson={shape_and_kml_to_geojsonData}
      />
    )} */}
      </div>
    </div>
  );
};

export default ProjectDetails;
