import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Chip,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  LayersControl,
  useMapEvents,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import "../Assets/Css/CommonStyles.css";
import L from "leaflet";
import LocationIcon from "../Assets/Mapicons/icons8-location-64.png";

const { BaseLayer } = LayersControl;

const getChipColor = (category) => {
  const categoryColors = {
    "REDD+": { backgroundColor: "#70C29C", color: "#FFF" },
    ARR: { backgroundColor: "#0E3B43", color: "#FFF" },
    IFM: { backgroundColor: "#513B3C", color: "#FFF" },
    // Add more categories and colors as needed
  };

  return (
    categoryColors[category] || { backgroundColor: "#F5CB5C", color: "#000" }
  );
};

const getStatusColor = (status) => {
  const statusColors = {
    completed: { backgroundColor: "#EF8354", color: "#FFF" },
    action_required: { backgroundColor: "#F5CB5C", color: "#000" },
    in_progress: { backgroundColor: "#0E3B43", color: "#FFF" },
    // Add more statuses and colors as needed
  };

  return statusColors[status] || { backgroundColor: "#70C29C", color: "#FFF" };
};

const customIcon = new L.Icon({
  iconUrl: LocationIcon,
  iconSize: [50, 50], // Size of the icon, adjust as needed
  iconAnchor: [20, 40], // Point of the icon which will correspond to marker's location
  popupAnchor: [0, -40], // Point from which the popup should open relative to the iconAnchor
});

const Home = () => {
  const [organization, setOrganization] = useState(null);
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();

  const handleAddProjectClick = () => {
    navigate("/add-project"); // Navigate to the add-project route
  };

  const handleEligiblityCheckClick = () => {
    navigate("/land-eligibility"); // Navigate to the add-project route
  };

  // Use the same origin (localhost or 127.0.0.1) consistently
  //const backendUrl = "http://localhost:8000";
  const backendUrl = `${process.env.REACT_APP_API_BASE_URL}`;
  console.log("Environment:", process.env.NODE_ENV);
  console.log("API Base URL:", process.env.REACT_APP_API_BASE_URL);
  console.log("backendUrl:", backendUrl);
  useEffect(() => {
    const token = localStorage.getItem("token"); // Retrieve token from local storage

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`, // Assuming the token is prefixed with 'Token'
    };

    // Fetch the organization data
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/customers/organizations/`,
      { headers }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
        return response.json(); // This line was missing
      })
      .then((data) => {
        setOrganization(data[0]);
      })
      .catch((error) => {
        console.error("Error fetching organization data:", error.message);
      });

    // Fetch the projects data
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/customers/projects/`, {
      headers,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
        return response.json(); // This line is necessary here as well
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setProjects(data);
        } else {
          console.error("Received non-array projects data:", data);
          setProjects([]);
        }
      })
      .catch((error) =>
        console.error("Error fetching projects data:", error.message)
      );
  }, []);

  // Map-related logic, assuming Leaflet and react-leaflet usage
  const MapClickHandler = ({ onClick }) => {
    useMapEvents({
      click: onClick,
    });
    return null;
  };

  const handleMapClick = (latlng) => {
    console.log(latlng); // Log or use these coordinates as needed
    // Assuming setCoordinates is defined to handle state for clicked coordinates
  };

  // Function to calculate the map center based on projects
  function calculateMapCenter() {
    if (projects.length === 0) {
      return [51.505, -0.09]; // Default center if no projects
    }
    // Calculate average latitude and longitude
    const totalCoords = projects.reduce(
      (total, project) => {
        return {
          latitude: total.latitude + project.latitude,
          longitude: total.longitude + project.longitude,
        };
      },
      { latitude: 0, longitude: 0 }
    );

    return [
      totalCoords.latitude / projects.length,
      totalCoords.longitude / projects.length,
    ];
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom sx={{ marginTop: "35px" }}>
        Welcome{" "}
        <span style={{ fontWeight: "bold", color: "#70C29C" }}>
          {organization ? organization.name : "XYZ Restoration Inc."}
        </span>
      </Typography>

      {organization ? (
        // Display organization summary if organization data is loaded
        <div>
          <h2>Company Summary</h2>
          {/* ... (Rest of the code) */}
          <MapContainer
            center={calculateMapCenter()}
            zoom={1.5}
            scrollWheelZoom={true}
            style={{ height: "400px", width: "100%" }}
          >
            <LayersControl position="topright">
              <BaseLayer name="OpenStreetMap">
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              </BaseLayer>
              <BaseLayer checked name="Esri World Imagery">
                {" "}
                {/* Set this layer as default */}
                <TileLayer
                  url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                  attribution="&copy; Esri &mdash; Sources: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                />
              </BaseLayer>
              {/* Add more layers as needed */}
              {/* LayersControl and other map layers */}
              <MapClickHandler onClick={handleMapClick} />
            </LayersControl>

            {projects.map((project, index) => (
              <Marker
                key={index}
                position={[project.latitude, project.longitude]}
                icon={customIcon}
              >
                <Popup>
                  <Typography
                    variant="h8"
                    sx={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    {project.name}
                  </Typography>
                  <br />
                  Country: {project.country}
                  <br />
                  Category: {project.category}
                  <br />
                  Status: {project.status}
                  <br />
                  Area (ha): {project.area_ha} ha
                  <br />
                  Net Emission Reduction: {project.net_emission_reduction} tCO2e
                  <br />
                  Total Landowners: {project.landowner_count}
                  <br />
                  Organisation Name: {project.organization_name}
                  <br />
                </Popup>
              </Marker>
            ))}
          </MapContainer>
          <hr />
          {/* Display organization summary if organization data is loaded */}
          {organization && (
            <div>
              <h2>Company Summary</h2>
              <hr />
              <TableContainer
                component={Paper}
                elevation={1}
                sx={{ marginY: "16px" }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Number of Projects
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Hectares Under Management
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Total tCO2e
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{organization.total_projects}</TableCell>
                      <TableCell>
                        {organization.hectares_under_management} ha
                      </TableCell>
                      <TableCell>{organization.total_tCO2e} tCO2e</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}

          <br />

          <Box sx={{ width: "100%", bgcolor: "background.paper", padding: 0 }}>
            <div>
              <hr />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  my: 2,
                }}
              >
                <Typography
                  variant="h5"
                  component="div"
                  gutterBottom
                  sx={{ fontWeight: "bold" }}
                >
                  All Projects
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Chip
                    label={projects.length}
                    color="secondary"
                    sx={{
                      mr: 2, // Adjust the margin as needed
                      backgroundColor: "#0E3B43", // Custom background color
                      color: "#FFF", // Adjust text color if needed
                    }}
                  />
                  {/* <Button
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    sx={{
                      color: "#70C29C", // Custom color for the text
                      fontWeight: "bold", // Make the text bold
                      mr: 2, // Adjust the margin as needed
                      "&:hover": {
                        color: "#0E3B43", // Keep the color on hover
                        borderColor: "#0E3B43", // Optionally, change border color on hover
                      },
                    }}
                    onClick={handleAddProjectClick} // Add the click handler
                  >
                    Add a Project
                  </Button> */}
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#4CAF50", // Custom background color
                      ":hover": {
                        backgroundColor: "#45a049", // Hover color
                      },
                    }}
                    startIcon={<CheckCircleOutlineIcon />}
                    onClick={handleEligiblityCheckClick}
                  >
                    VM0047 Eligibility Check
                  </Button>
                </Box>
              </Box>
              <hr />

              <List component={Paper} sx={{ marginTop: 2 }}>
                {projects.map((project, index) => (
                  <ListItem
                    key={index}
                    secondaryAction={
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Chip
                          label={project.country}
                          sx={{
                            ml: 1,
                            height: 32,
                            backgroundColor: "#F5CB5C",
                            color: "#000",
                          }}
                        />
                        <Chip
                          label={project.category}
                          sx={{
                            ...getChipColor(project.category),
                            ml: 1,
                            height: 32,
                          }}
                        />
                        <Chip
                          label={project.status.replace("_", " ")}
                          sx={{
                            ...getStatusColor(project.status),
                            ml: 1,
                            height: 32,
                          }}
                        />
                        <Link
                          to={`/land-eligibility-analysis/${project.id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              ml: 1, // Margin left
                              backgroundColor: "#513B3C", // Custom background color
                              color: "#FFF", // Text color, white for better readability
                              "&:hover": {
                                backgroundColor: "#0E3B43", // A slightly darker shade for the hover state
                              },
                            }}
                          >
                            Project Details
                          </Button>
                        </Link>
                      </Box>
                    }
                  >
                    <ListItemText
                      primary={
                        <Typography
                          variant="body1"
                          component="span"
                          sx={{ fontWeight: "bold" }}
                        >
                          {project.name}
                        </Typography>
                      }
                      secondary={`${project.area_ha} ha`}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          </Box>
        </div>
      ) : (
        // If the user is not associated with any organization, provide a registration link
        <div>
          <Typography variant="h5" gutterBottom>
            You are not associated with any organization. Please{" "}
            <Link to="/register-organization">register an organization</Link>{" "}
            first.
          </Typography>
        </div>
      )}
    </Container>
  );
};

export default Home;
