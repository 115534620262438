import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  Alert,
} from "@mui/material";
import LoginIcon from "@mui/icons-material/Login"; // Import the icon

function Login({ setIsAuthenticated }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false); // State for success message
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Environment:", process.env.NODE_ENV);
    console.log("API Base URL:", process.env.REACT_APP_API_BASE_URL);
    //const loginUrl = `${process.env.REACT_APP_API_BASE_URL}/api/customers/login/`;

    const loginUrl = `https://demoapp.jeev.earth/api/customers/login/`;
    console.log("loginUrl:", loginUrl);

    try {
      const response = await axios.post(loginUrl, {
        username,
        password,
      });
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("user", JSON.stringify(response.data.user));

      setSuccess(true); // Set success message
      setIsAuthenticated(true); // Update authentication state

      navigate("/"); // Redirect to home page after success
    } catch (error) {
      setError(error.response?.data?.detail || "Login failed!");
    }
  };

  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5" gutterBottom>
          <LoginIcon
            sx={{
              mr: 1,
              verticalAlign: "middle",
              fontSize: 40,
              color: "#70C29C", // Set the color of the icon
            }}
          />
          LOGIN
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">Login Successful!</Alert>}
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              backgroundColor: "#70C29C", // Set the button color to green
              "&:hover": {
                backgroundColor: "#0E3B43", // Optional: Change color on hover
              },
            }}
          >
            LOGIN
          </Button>
          <Button
            fullWidth
            sx={{
              mt: 3,
              mb: 2,
              backgroundColor: "#0E3B43", // Custom color
              color: "#FFF6EB", // Text color (optional, change as needed)
              "&:hover": {
                backgroundColor: "#0b2a33", // Darker shade for hover state (optional)
              },
            }}
            onClick={() => navigate("/register")}
          >
            Don't have an account? SIGN UP
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default Login;
