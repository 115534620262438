import React from "react";
import { LayerGroup, Circle, LayersControl } from "react-leaflet";

const CreateCircleLayer = ({ circleCenter }) => {
  const createCircleLayer = (radius) => {
    return (
      <LayerGroup>
        {circleCenter && (
          <Circle center={circleCenter} radius={radius} color="red" />
        )}
      </LayerGroup>
    );
  };

  return (
    <>
      {/* The following layers are conceptually under the "Radius Circle" category */}
      <LayersControl.Overlay name="1 km Radius Circle">
        {createCircleLayer(1000)}
      </LayersControl.Overlay>
      <LayersControl.Overlay name="5 km Radius Circle">
        {createCircleLayer(5000)}
      </LayersControl.Overlay>
      <LayersControl.Overlay name="10 km Radius Circle">
        {createCircleLayer(10000)}
      </LayersControl.Overlay>
      <LayersControl.Overlay name="50 km Radius Circle">
        {createCircleLayer(50000)}
      </LayersControl.Overlay>
    </>
  );
};

export default CreateCircleLayer;
