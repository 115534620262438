import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  CircularProgress,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "leaflet/dist/leaflet.css";
import styled from "styled-components";
import { MapContainer, TileLayer, LayersControl } from "react-leaflet";
import L from "leaflet";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

const AreaInfoCard = styled.div`
  width: 100%;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
`;

const InfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  color: ${(props) => props.color || "black"};
`;

const InfoIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const MapWrapper = styled.div`
  width: 100%;
  height: 70vh; /* Set the map height to cover the whole page height */
  border: 1px solid #ccc;
`;

const LandEligibilityAnalysis = () => {
  const { projectId } = useParams();
  const [geoJson, setGeoJson] = useState(null);
  const [areaInfo, setAreaInfo] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state
  const [openDialog, setOpenDialog] = useState(false); // State for dialog
  const mapRef = useRef(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token not found. Redirecting to the login page.");
      return;
    }

    const fetchFile = async () => {
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/customers/projects/${projectId}/fetch_file/`;
      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        if (response.data) {
          const { data } = response.data;
          setGeoJson(data);
        }
      } catch (error) {
        console.error("Error fetching file data:", error);
      }
    };

    const fetchLandEligibility = async () => {
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/customers/projects/${projectId}/land-eligibility/`;
      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        if (response.data) {
          const { tile_urls, area } = response.data;

          setAreaInfo(area);
          setLoading(false); // Set loading to false when data is fetched

          // Set up the map layers
          const layers = {
            "Eligible Land": L.tileLayer(tile_urls.eligibility || "", {
              attribution: "Land Eligibility © Google Earth Engine",
            }),
            "Ineligible Land": L.tileLayer(tile_urls.ineligible || "", {
              attribution: "Land Eligibility © Google Earth Engine",
            }),
            "Ineligible: Deforested <10 years": L.tileLayer(
              tile_urls.ineligibleDeforested || "",
              { attribution: "Land Eligibility © Google Earth Engine" }
            ),
            "Ineligible: Land Classification": L.tileLayer(
              tile_urls.ineligibleLandClass || "",
              { attribution: "Land Eligibility © Google Earth Engine" }
            ),
            "Ineligible: Both": L.tileLayer(tile_urls.bothIneligible || "", {
              attribution: "Land Eligibility © Google Earth Engine",
            }),
          };

          const overlayMaps = {
            "Eligible Land": layers["Eligible Land"],
            "Ineligible Land": layers["Ineligible Land"],
            "Ineligible: Deforested <10 years":
              layers["Ineligible: Deforested <10 years"],
            "Ineligible: Land Classification":
              layers["Ineligible: Land Classification"],
            "Ineligible: Both": layers["Ineligible: Both"],
          };

          // Adding layers to the map
          const map = mapRef.current;
          if (map) {
            L.control.layers(null, overlayMaps).addTo(map);

            // Only add the default selected layers
            layers["Eligible Land"].addTo(map);
            layers["Ineligible Land"].addTo(map);
          }
        }
      } catch (error) {
        console.error("Error fetching land eligibility data:", error);
        setLoading(false); // Set loading to false if there's an error
      }
    };

    fetchFile();
    fetchLandEligibility();
  }, [projectId]);

  useEffect(() => {
    if (geoJson && mapRef.current) {
      const boundaryLayer = L.geoJSON(geoJson, {
        style: { color: "#3388ff" },
      }).addTo(mapRef.current);
      const bounds = boundaryLayer.getBounds();
      mapRef.current.fitBounds(bounds);
      mapRef.current.setMaxBounds(bounds); // Restrict the map to the bounds of the AOI
    }
  }, [geoJson]);

  const eligiblePercentage =
    areaInfo && areaInfo.total > 0
      ? (areaInfo.eligible / areaInfo.total) * 100
      : 0;
  const ineligiblePercentage =
    areaInfo && areaInfo.total > 0
      ? (areaInfo.ineligible / areaInfo.total) * 100
      : 0;
  const deforestedPercentage =
    areaInfo && areaInfo.total > 0
      ? (areaInfo.deforested / areaInfo.total) * 100
      : 0;
  const landClassPercentage =
    areaInfo && areaInfo.total > 0
      ? (areaInfo.landClass / areaInfo.total) * 100
      : 0;
  const bothIneligiblePercentage =
    areaInfo && areaInfo.total > 0 ? (areaInfo.both / areaInfo.total) * 100 : 0;

  const handleDownload = async (landType) => {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/customers/projects/${projectId}/download-kml/${landType}/`;
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
        responseType: "blob", // Important for file downloads
      });

      const blob = new Blob([response.data], {
        type: "application/vnd.google-earth.kml+xml",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", `${landType}_land_${projectId}.kml`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading KML file:", error);
    }
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <div>
      <MapWrapper>
        <MapContainer
          center={[37.5, -122.2]}
          zoom={10}
          scrollWheelZoom={true}
          style={{ height: "100%", width: "100%" }}
          ref={mapRef}
        >
          <LayersControl position="topright">
            <LayersControl.BaseLayer checked name="OpenStreetMap">
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Esri World Imagery">
              <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Topographic">
              <TileLayer url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png" />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Watercolor">
              <TileLayer url="https://{s}.tile.stamen.com/watercolor/{z}/{x}/{y}.jpg" />
            </LayersControl.BaseLayer>
          </LayersControl>
        </MapContainer>
      </MapWrapper>

      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "20vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        areaInfo && (
          <AreaInfoCard>
            <Typography
              variant="h6"
              sx={{
                mb: 1,
                fontWeight: "bold",
                textAlign: "center",
                color: "#0E3B43",
              }}
            >
              RESULTS
              <IconButton onClick={handleDialogOpen}>
                <InfoIcon />
              </IconButton>
            </Typography>
            <Box sx={{ mb: 2 }}>
              <LinearProgress
                variant="determinate"
                value={eligiblePercentage}
                sx={{
                  height: 10,
                  borderRadius: 5,
                  backgroundColor: "#e0e0e0",
                  "& .MuiLinearProgress-bar": {
                    borderRadius: 5,
                    backgroundColor: "#76c7c0",
                  },
                }}
              />
              <Typography
                variant="body2"
                sx={{
                  mt: 1,
                  textAlign: "right",
                  color: "#0E3B43",
                  fontWeight: "bold",
                }}
              >
                {eligiblePercentage.toFixed(2)}% eligible
              </Typography>
            </Box>
            <InfoItem color="black">
              <InfoIconWrapper>
                <InfoIcon style={{ color: "#0E3B43" }} />
                <span>Total Area</span>
              </InfoIconWrapper>
              <span>{areaInfo.total?.toFixed(2) ?? "0"} ha</span>
            </InfoItem>
            <InfoItem color="green">
              <InfoIconWrapper>
                <CheckCircleIcon style={{ color: "#70c29c" }} />
                <span>Eligible land</span>
              </InfoIconWrapper>
              <span>{areaInfo.eligible?.toFixed(2) ?? "0"} ha</span>
              <Button
                variant="contained"
                sx={{ backgroundColor: "#70c29c", color: "#fff" }}
                onClick={() => handleDownload("eligible")}
              >
                Download KML
              </Button>
            </InfoItem>
            <InfoItem color="red">
              <InfoIconWrapper>
                <CancelIcon style={{ color: "#EF8354" }} />
                <span>Ineligible land</span>
              </InfoIconWrapper>
              <span>{areaInfo.ineligible?.toFixed(2) ?? "0"} ha</span>
              <Button
                variant="contained"
                sx={{ backgroundColor: "#EF8354", color: "#fff" }}
                onClick={() => handleDownload("ineligible")}
              >
                Download KML
              </Button>
            </InfoItem>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <FontAwesomeIcon icon={faAngleDown} />
              </AccordionSummary>
              <AccordionDetails>
                <InfoItem color="purple">
                  <InfoIconWrapper>
                    <InfoIcon style={{ color: "#0E2B43" }} />
                    <span>Ineligible: Deforested &lt;10 years</span>
                  </InfoIconWrapper>
                  <span>
                    {areaInfo.deforested?.toFixed(2) ?? "0"} ha (
                    {deforestedPercentage.toFixed(2) || "0"}%)
                  </span>
                </InfoItem>
                <InfoItem color="blue">
                  <InfoIconWrapper>
                    <InfoIcon style={{ color: "#513B3C" }} />
                    <span>Ineligible: Land Classification</span>
                  </InfoIconWrapper>
                  <span>
                    {areaInfo.landClass?.toFixed(2) ?? "0"} ha (
                    {landClassPercentage.toFixed(2) || "0"}%)
                  </span>
                </InfoItem>
                <InfoItem color="orange">
                  <InfoIconWrapper>
                    <InfoIcon style={{ color: "#F5CB5C" }} />
                    <span>Ineligible: Both</span>
                  </InfoIconWrapper>
                  <span>
                    {areaInfo.both?.toFixed(2) ?? "0"} ha (
                    {bothIneligiblePercentage.toFixed(2) || "0"}%)
                  </span>
                </InfoItem>
              </AccordionDetails>
            </Accordion>
          </AreaInfoCard>
        )
      )}

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Information</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <strong>Data Sources:</strong>
            <p>
              Hansen, M. C., P. V. Potapov, R. Moore, M. Hancher, S. A.
              Turubanova, A. Tyukavina, D. Thau, S. V. Stehman, S. J. Goetz, T.
              R. Loveland, A. Kommareddy, A. Egorov, L. Chini, C. O. Justice,
              and J. R. G. Townshend (2013) High-Resolution Global Maps of
              21st-Century Forest Cover Change. Science 342 (15 November):
              850-53. 10.1126/science.1244693.{" "}
              <a
                href="https://agupubs.onlinelibrary.wiley.com/doi/10.1029/2005RG000183"
                target="_blank"
                rel="noopener noreferrer"
              >
                Data available online
              </a>
              .
            </p>
            <p>
              K. Karra, C. Kontgis, Z. Statman-Weil, J. C. Mazzariello, M.
              Mathis and S. P. Brumby, "Global Land Use / Land Cover with
              Sentinel 2 and Deep Learning," 2021 IEEE International Geoscience
              and Remote Sensing Symposium IGARSS, Brussels, Belgium, pp.
              4704-4707, doi: 10.1109/IGARSS47720.2021.9553499.
            </p>

            <strong>Disclaimer:</strong>
            <p>
              The information provided on this website (http://35.214.76.80) is
              for general informational purposes only. All information on the
              site is provided in good faith, however, we make no representation
              or warranty of any kind, express or implied, regarding the
              accuracy, adequacy, validity, reliability, availability, or
              completeness of any information on the site. This tool provides a
              preliminary guidance on land eligibility. Final project
              eligibility will likely differ significantly depending on project
              setup. Jeev does not provide financial, investment or any other
              similar type of advice.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LandEligibilityAnalysis;
