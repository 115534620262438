// ProjectHeader.js
import React from "react";
import { Link } from "react-router-dom";
import { Typography, Button, Box, Chip } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ShapeFileDownload from "../Analysis/ShapeFileDownload";

const ProjectHeader = ({
  project,
  projectId,
  handleNavigate,
  getChipColor,
  getStatusColor,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
        mb: 2,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Button
          component={Link}
          to="/"
          startIcon={<ArrowBackIosNewIcon />}
          sx={{ mr: 2 }}
        >
          Back
        </Button>
        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          {project.name}{" "}
          <Button variant="contained" color="primary" onClick={handleNavigate}>
            Edit
          </Button>
        </Typography>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <ShapeFileDownload projectId={projectId} />
        <Chip
          label={project.country}
          sx={{
            ml: 1,
            height: 32,
            backgroundColor: "#F5CB5C",
            color: "#000",
          }}
        />
        <Chip
          label={project.category}
          sx={{ ...getChipColor(project.category), ml: 1, height: 32 }}
        />
        <Chip
          label={project.status.replace("_", " ").toUpperCase()}
          sx={{ ...getStatusColor(project.status), ml: 1, height: 32 }}
        />
      </Box>
    </Box>
  );
};

export default ProjectHeader;
