import React, { useEffect } from "react";
import L from "leaflet";
import { useMap } from "react-leaflet";

const RasterOverlay = ({ tileUrl }) => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    // Define the raster tile layer
    const rasterLayer = L.tileLayer(tileUrl, {
      attribution: "Raster Data",
      maxZoom: 16,
      minZoom: 2,
    });

    // For debugging purposes
    rasterLayer.on("tileloadstart", function (event) {
      console.log("Starting to load tile:", event.tile.src);
    });

    rasterLayer.on("tileerror", function (error) {
      console.log("Tile loading error:", error);
    });

    // Log when all tiles are loaded
    rasterLayer.on("load", () => {
      console.log("All tiles loaded.");
    });

    console.log("Tile URL:", tileUrl); // Log the tile URL for debugging
    console.log("Raster Layer:", rasterLayer); // Log the raster layer for debugging

    // Add the raster layer to the map
    rasterLayer.addTo(map);

    // This function will be called when the component unmounts
    return () => {
      if (map.hasLayer(rasterLayer)) {
        map.removeLayer(rasterLayer);
      }
    };
  }, [map, tileUrl]); // Re-run this effect if map or tileUrl changes

  return null; // This component does not render anything itself
};

export default RasterOverlay;
