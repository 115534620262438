import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import {
  IconButton,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Tooltip,
  Collapse,
  Typography,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import ExpandLess from "@mui/icons-material/ExpandLess"; // Import for the expand less icon
import ExpandMore from "@mui/icons-material/ExpandMore"; // Import for the expand more icon
import TextGenerationComponent from "./TextGenerationComponent";
import TiffTextDescriptionComponent from "./TiffTextDescriptionComponent";

const LeafletMapSidebar = () => {
  const { projectId, token: urlToken } = useParams();
  console.log("Debug projectId:", projectId); // Check the projectId right after retrieval
  const location = useLocation();
  const { latitude, longitude } = location.state || {
    latitude: 37.5,
    longitude: 71.0,
  };
  const [tiffData, setTiffData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTiff, setSelectedTiff] = useState(null);
  const [openGroups, setOpenGroups] = useState({}); // State to track open/closed state of groups
  const [analysisImageUrl, setAnalysisImageUrl] = useState(null);
  const [barChartUrl, setBarChartUrl] = useState(null);
  const [pieChartUrl, setPieChartUrl] = useState(null);
  const [chartData, setChartData] = useState({
    barChart: null,
    pieChart: null,
  });
  const mapRef = useRef(null);
  const currentTiffLayerRef = useRef(null); // Ref to store the current TIFF overlay
  const [selectedId, setSelectedId] = useState(null); // State to manage the selected TIFF id

  const drawerWidth = 280;

  useEffect(() => {
    const fetchTiffData = async () => {
      const token = localStorage.getItem("token") || urlToken;

      if (!token) {
        console.error("Token not found. Redirect to the login page.");
        return;
      }

      try {
        const config = { headers: { Authorization: `Token ${token}` } };
        const apiUrl = `http://127.0.0.1:8000/api/customers/projects/${projectId}/tiff/`;
        const response = await axios.get(apiUrl, config);

        if (response.status === 200) {
          // Group TIFF data by analysis_group_name
          const groupedData = response.data.reduce((acc, item) => {
            acc[item.analysis_group_name] = acc[item.analysis_group_name] || [];
            acc[item.analysis_group_name].push(item);
            return acc;
          }, {});

          setTiffData(groupedData);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching TIFF data:", error);
      }
    };

    fetchTiffData();
  }, [projectId, urlToken]);

  useEffect(() => {
    if (!isLoading && !mapRef.current) {
      mapRef.current = L.map("map", {
        center: [latitude, longitude],
        zoom: 13,
      });

      const openStreetMapLayer = L.tileLayer(
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        {
          attribution: "© OpenStreetMap contributors",
        }
      );

      const esriWorldImageryLayer = L.tileLayer(
        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
        {
          attribution:
            "Tiles © Esri — Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
        }
      );

      const baseLayers = {
        OpenStreetMap: openStreetMapLayer,
        "Esri World Imagery": esriWorldImageryLayer,
      };

      // Add OpenStreetMap as the default layer
      openStreetMapLayer.addTo(mapRef.current);

      // Layer control to switch between base layers
      L.control.layers(baseLayers).addTo(mapRef.current);

      // Listen for base layer change to reapply TIFF overlay
      mapRef.current.on("baselayerchange", (event) => {
        // Check if there's a currently selected TIFF to be re-applied
        if (currentTiffLayerRef.current) {
          // Remove the previous TIFF overlay if it's on the map
          if (mapRef.current.hasLayer(currentTiffLayerRef.current)) {
            mapRef.current.removeLayer(currentTiffLayerRef.current);
          }
          // Add the TIFF overlay back to the map
          currentTiffLayerRef.current.addTo(mapRef.current);
        }
      });
    }
  }, [isLoading, latitude, longitude]);

  // Handlers for toggling group open state
  const handleGroupClick = (groupName) => {
    setOpenGroups((prevOpenGroups) => ({
      ...prevOpenGroups,
      [groupName]: !prevOpenGroups[groupName],
    }));
  };

  const handleTiffSelect = async (selectedId) => {
    setSelectedTiff(selectedId);
    setSelectedId(selectedId); // Save the selected TIFF ID
    // Reset the analysis image and chart data
    setAnalysisImageUrl(null);
    setChartData({
      barChart: null,
      pieChart: null,
    });

    // Delay before fetching analysis data
    await new Promise((resolve) => setTimeout(resolve, 1000));

    const tiff = Object.values(tiffData)
      .flat()
      .find((tiffItem) => tiffItem.id === selectedId);

    if (mapRef.current && tiff) {
      // Remove previous TIFF layer if exists
      if (currentTiffLayerRef.current) {
        mapRef.current.removeLayer(currentTiffLayerRef.current);
      }

      // Add new TIFF layer
      const tiffLayer = L.tileLayer
        .wms("http://localhost:8080/geoserver/geoapp/wms", {
          layers: tiff.name,
          format: "image/png",
          transparent: true,
          version: "1.1.0",
        })
        .addTo(mapRef.current);

      // Bring TIFF layer to front
      tiffLayer.bringToFront();
      currentTiffLayerRef.current = tiffLayer;

      // Fetch analysis data
      const analysisUrl = `http://127.0.0.1:8000/api/customers/projects/${projectId}/tiff/${selectedId}/analysis/`;
      console.log("analysisUrl", analysisUrl);
      const token = localStorage.getItem("token") || urlToken;
      try {
        const response = await axios.get(analysisUrl, {
          headers: { Authorization: `Token ${token}` },
        });
        if (response.data && response.data.image_base64) {
          // Directly use the base64-encoded image as the source for the chart image
          setChartData({
            // Assuming the base64 data is meant for a bar chart; adjust as needed
            barChart: `data:image/png;base64,${response.data.image_base64}`,
            // No pie chart URL received, adjust your server response or frontend handling as needed
            pieChart: null,
          });
          console.log("Received chart data:", response.data);
        }
      } catch (error) {
        console.error("Error fetching analysis data:", error);
      }
    }
  };

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#f5f5f5",
            color: "#3f51b5",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <List>
          {Object.entries(tiffData).map(
            ([groupName, tiffFiles], groupIndex) => (
              <React.Fragment key={groupName}>
                <ListItem button onClick={() => handleGroupClick(groupName)}>
                  <ListItemText
                    primary={groupName}
                    primaryTypographyProps={{ style: { fontWeight: "bold" } }}
                  />
                  {openGroups[groupName] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                  in={openGroups[groupName]}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding dense sx={{ pb: 0 }}>
                    {" "}
                    {/* Additional styling for compactness */}
                    {tiffFiles.map((tiff, tiffIndex) => (
                      <ListItem
                        key={tiff.name}
                        selected={tiff.id === selectedTiff}
                        onClick={() => handleTiffSelect(tiff.id)}
                        sx={{
                          pl: 0, // Maintain indentation
                          py: 0, // Reduce vertical padding for compactness
                          fontSize: 14, // Set smaller font size
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: 32, minHeight: 18 }}>
                          {" "}
                          {/* Fixed icon size for better spacing */}
                          {tiff.id === selectedTiff && (
                            <CheckCircleIcon fontSize="small" />
                          )}{" "}
                          {/* Concise conditional rendering */}
                        </ListItemIcon>
                        <ListItemText
                          primary={tiff.name}
                          primaryTypographyProps={{ noWrap: true }}
                        />{" "}
                        {/* Prevent text wrapping */}
                        <Tooltip title={tiff.source}>
                          <IconButton sx={{ pl: 8 }}>
                            {" "}
                            {/* Adjust icon spacing */}
                            <InfoIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </React.Fragment>
            )
          )}
        </List>
      </Drawer>

      <Box sx={{ width: `calc(100% - ${drawerWidth}px)`, flexGrow: 1 }}>
        {/* Leaflet Map */}
        <Box
          sx={{
            width: "100%",
            height: "50vh", // 50% of the viewport height
          }}
        >
          <IconButton
            component={Link}
            to={`/project-details/${projectId}`}
            sx={{
              position: "absolute",
              top: 2,
              left: 5,
              zIndex: 100,
              backgroundColor: "#fff",
              "&:hover": { backgroundColor: "#f5f5f5" },
            }}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
          <div id="map" style={{ width: "100%", height: "100%" }}></div>
        </Box>

        {/* Charts */}
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {chartData.barChart && (
            <Box sx={{ width: "125%", height: "500px", overflow: "hidden" }}>
              <img
                src={chartData.barChart}
                alt="Bar Chart"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </Box>
          )}
          {chartData.pieChart && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography variant="h6">Pie Chart</Typography>
              <img
                src={chartData.pieChart}
                alt="Pie Chart"
                style={{ maxWidth: "100%", maxHeight: "80%" }}
              />
            </Box>
          )}
          {/* Conditionally render the placeholder text if either chart is visible */}
          {(chartData.barChart || chartData.pieChart) && (
            <Box
              sx={{
                width: "100%",
                backgroundColor: "#f5f5f5",
                borderLeft: "1px solid #ddd",
                overflowY: "auto", // Enable vertical scrolling
                maxHeight: "45vh", // Adjust maxHeight for layout
                padding: "1rem", // Adjust padding for spacing
              }}
            >
              {/* Scrollable Text Content */}
              <Typography
                variant="body1"
                sx={{ fontSize: 16, lineHeight: 0.5 }}
              >
                {/* Here we use the ProjectMarker component */}
                {/* <TextGenerationComponent
        projectId={projectId}
        selectedId={selectedId}
      /> */}

                <TiffTextDescriptionComponent
                  projectId={projectId}
                  selectedId={selectedId}
                />
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default LeafletMapSidebar;
