import React, { useState } from "react";
import { Box, Typography, Grid, Button, IconButton } from "@mui/material";
import axios from "axios";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Include Quill CSS for text editor styling

const Report = ({ projectId }) => {
  const [sections, setSections] = useState([
    {
      title: "PROJECT DETAILS",
      subsections: [
        { title: "1.1 Project Overview", content: "" },
        { title: "1.2 Project Location", content: "" },
        { title: "1.3 Project Timeline", content: "" },
      ],
      open: false,
    },
    {
      title: "SAFEGUARDS",
      subsections: [
        { title: "2.1 Environmental Safeguards", content: "" },
        { title: "2.2 Social Safeguards", content: "" },
      ],
    },
    {
      title: "APPLICATION OF METHODOLOGY",
      subsections: [
        { title: "3.1 Methodology Framework", content: "" },
        { title: "3.2 Data Collection and Analysis", content: "" },
      ],
    },
    {
      title: "QUANTIFICATION",
      subsections: [
        { title: "4.1 Quantification Procedures", content: "" },
        { title: "4.2 Results and Interpretation", content: "" },
      ],
    },
    {
      title: "MONITORING",
      subsections: [
        { title: "5.1 Monitoring Plan", content: "" },
        { title: "5.2 Reporting", content: "" },
      ],
    },
    {
      title: "APPENDIX",
      subsections: [
        { title: "6.1 Supporting Documents", content: "" },
        { title: "6.2 Additional Information", content: "" },
      ],
    },
    // Add more sections as needed
  ]);

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("image", file); // Assuming 'image' is the field expected by your backend

    try {
      const response = await axios.post(
        "https://yourserver.com/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // Assuming the response contains the URL of the uploaded image in response.data.url
      return response.data.url;
    } catch (error) {
      console.error("Error uploading image:", error);
      return ""; // Return an empty string or handle the error appropriately
    }
  };

  const [activeSection, setActiveSection] = useState(0); // Track which section is active

  const handleQuillChange = (content, subsectionIndex) => {
    const newSections = sections.map((section, sIndex) => {
      if (sIndex === activeSection) {
        return {
          ...section,
          subsections: section.subsections.map((sub, subIndex) => {
            if (subIndex === subsectionIndex) {
              return { ...sub, content: content };
            }
            return sub;
          }),
        };
      }
      return section;
    });
    setSections(newSections);
  };

  const handleImageUpload = (e, subsectionIndex) => {
    const file = e.target.files[0];
    if (file) {
      // Assume a function uploadImage exists to handle the upload process
      // For demonstration, this code snippet doesn't implement the uploadImage function
      uploadImage(file).then((imageUrl) => {
        const newSections = sections.map((section, sIndex) => {
          if (sIndex === activeSection) {
            return {
              ...section,
              subsections: section.subsections.map((sub, subIndex) => {
                if (subIndex === subsectionIndex) {
                  return { ...sub, imageUrl };
                }
                return sub;
              }),
            };
          }
          return section;
        });
        setSections(newSections);
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        {sections.map((section, index) => (
          <Button
            key={section.title}
            onClick={() => setActiveSection(index)}
            sx={{
              textTransform: "none",
              justifyContent: "flex-start",
              width: "100%",
            }}
            variant={activeSection === index ? "contained" : "text"}
          >
            <Typography
              variant="subtitle1"
              sx={{
                color: activeSection === index ? "#fff" : "#3f51b5",
                fontWeight: "bold",
              }}
            >
              {section.title}
            </Typography>
          </Button>
        ))}
      </Grid>
      <Grid item xs={12} md={9}>
        <Box
          sx={{
            borderRadius: "15px",
            backgroundColor: "#f5f5f5",
            padding: "20px",
          }}
        >
          {sections[activeSection].subsections.map(
            (subsection, subsectionIndex) => (
              <Box key={subsection.title} mt={2}>
                <Typography variant="subtitle1">{subsection.title}</Typography>
                <ReactQuill
                  theme="snow"
                  value={subsection.content}
                  onChange={(content) =>
                    handleQuillChange(content, subsectionIndex)
                  }
                />
                <Box display="flex" alignItems="center" mt={2}>
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id={`file-input-${subsectionIndex}`}
                    multiple
                    type="file"
                    onChange={(e) => handleImageUpload(e, subsectionIndex)}
                  />
                  <label htmlFor={`file-input-${subsectionIndex}`}>
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <CloudUploadIcon />
                    </IconButton>
                  </label>
                  {subsection.imageUrl && (
                    <img
                      src={subsection.imageUrl}
                      alt="Uploaded"
                      style={{ maxHeight: "100px", marginLeft: "10px" }}
                    />
                  )}
                </Box>
              </Box>
            )
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Report;
