import L from "leaflet";
import LocationIcon from "../Assets/Mapicons/icons8-location-64.png";
// Function to fit the map bounds to the GeoJSON data
export const fitMapToGeoJson = (mapRef, geojsonData) => {
  if (mapRef.current && geojsonData) {
    const geojsonLayer = L.geoJSON(geojsonData);
    const bounds = geojsonLayer.getBounds();
    mapRef.current.leafletElement.fitBounds(bounds);
  }
};

export const getChipColor = (category) => {
  const categoryColors = {
    "REDD+": { backgroundColor: "#70C29C", color: "#FFF" },
    ARR: { backgroundColor: "#0E3B43", color: "#FFF" },
    IFM: { backgroundColor: "#513B3C", color: "#FFF" },
    // Add more categories and colors as needed
  };

  return (
    categoryColors[category] || { backgroundColor: "#F5CB5C", color: "#000" }
  );
};

export const getStatusColor = (status) => {
  const statusColors = {
    completed: { backgroundColor: "#EF8354", color: "#FFF" },
    action_required: { backgroundColor: "#F5CB5C", color: "#000" },
    in_progress: { backgroundColor: "#0E3B43", color: "#FFF" },
    // Add more statuses and colors as needed
  };

  return statusColors[status] || { backgroundColor: "#70C29C", color: "#FFF" };
};

export const customIcon = new L.Icon({
  iconUrl: LocationIcon,
  iconSize: [45, 55],
  iconAnchor: [22, 94],
  popupAnchor: [-3, -76],
});

export const renderCustomMarker = (feature, latLng) => {
  if (feature.geometry.type === "Point") {
    return L.marker(latLng, { icon: customIcon });
  }
  // Customize for non-point features as needed
};

export const calculateBounds = (geojsonData) => {
  let minLat = 90,
    minLng = 180,
    maxLat = -90,
    maxLng = -180;

  geojsonData.features.forEach((feature) => {
    feature.geometry.coordinates.forEach((polygon) => {
      polygon.forEach(([lng, lat]) => {
        if (lat < minLat) minLat = lat;
        if (lng < minLng) minLng = lng;
        if (lat > maxLat) maxLat = lat;
        if (lng > maxLng) maxLng = lng;
      });
    });
  });

  return [
    [minLat, minLng],
    [maxLat, maxLng],
  ];
};
