import React, { useEffect } from "react";
import { useMap } from "react-leaflet";
import axios from "axios";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

const GEELandClassificationMap10m = ({ projectId, geoJson }) => {
  const map = useMap();
  console.log("GEELandClassificationMap10m-projectId", projectId);
  console.log("GEELandClassificationMap10m-geoJson", geoJson);

  useEffect(() => {
    // Fetch and display the GEE data layer
    const fetchLandClassification = async () => {
      const apiUrl = `http://127.0.0.1:8000/api/customers/projects/${projectId}/land-classification/`;
      try {
        const response = await axios.get(apiUrl);
        if (response.data && response.data.tile_url) {
          const landLayer = L.tileLayer(response.data.tile_url, {
            attribution: "Land Classification © Google Earth Engine",
          }).addTo(map);
          landLayer.bringToFront();
        }
      } catch (error) {
        console.error("Error fetching land classification data:", error);
      }
    };

    fetchLandClassification();

    // Add project boundary GeoJSON to the map
    if (geoJson) {
      const boundaryLayer = L.geoJSON(geoJson, {
        style: { color: "#3388ff" },
      }).addTo(map);
      map.fitBounds(boundaryLayer.getBounds());
    }
  }, [map, projectId, geoJson]);

  return null; // No need to render anything specific, since we're working directly with the map instance
};

export default GEELandClassificationMap10m;
