import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  Typography,
  Snackbar,
  IconButton,
  Box,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";

const GeoJsonUpload = ({ projectId }) => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      setMessage("Please select a file to upload.");
      setOpen(true);
      return;
    }

    const formData = new FormData();
    formData.append("analysis_name", "Land use analysis"); // Replace with actual analysis name
    formData.append("geojson_file", file);

    try {
      const response = await axios.post(
        `http://127.0.0.1:8000/api/customers/projects/${projectId}/upload-geojson/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            // Add your auth token here if required
          },
        }
      );

      setMessage("Status: " + response.data.message);
    } catch (error) {
      setMessage("Error uploading file: " + error.message);
      console.error("Upload error:", error);
    } finally {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ height: "10vh" }}>
      <Box sx={{ my: 4 }}>
        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item xs={12} sm={4} md={8}>
            <Card sx={{ p: 0 }}>
              <CardContent>
                <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      mb: 1,
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      textAlign: "center",
                      color: "#0E3B43",
                    }}
                  >
                    LAND USE ANALYSIS
                  </Typography>
                </Box>
                <input
                  accept=".json"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  type="file"
                  onChange={handleFileChange}
                />
                <label htmlFor="raised-button-file">
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Upload GeoJSON file
                  </Button>
                </label>
                <Button
                  variant="contained"
                  color="warning"
                  onClick={handleUpload}
                  sx={{ mt: 1, display: "flex", justifyContent: "center" }}
                >
                  Upload
                </Button>
              </CardContent>
            </Card>
          </Grid>
          {/* ... other Grid items */}
        </Grid>

        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message={message}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </Box>
    </div>
  );
};

export default GeoJsonUpload;
