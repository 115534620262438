import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "leaflet/dist/leaflet.css";
import styled from "styled-components";

const MapAreaContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
`;

const MapContainer = styled.div`
  width: 75%;
  height: 500px;
  border: 1px solid #ccc;
`;

const AreaInfoCard = styled.div`
  width: 25%;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const InfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const GEELandEligibilityMap = () => {
  const [formData, setFormData] = useState({
    name: "",
    country: "",
    category: "",
    status: "",
    project_progress: "",
    area_ha: 0,
    net_emission_reduction: 0,
    latitude: 0,
    longitude: 0,
    organization: "",
  });
  const [useShapeFile, setUseShapeFile] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const [geoJson, setGeoJson] = useState(null); // GeoJSON for project boundary
  const [mapVisible, setMapVisible] = useState(false); // State to control map visibility
  const [fileUploaded, setFileUploaded] = useState(false); // State to track file upload status
  const mapRef = useRef(null); // Reference to store the Leaflet map
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const token = localStorage.getItem("token");
        const config = {
          headers: {
            Authorization: `Token ${token}`,
          },
        };
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/customers/organizations/`,
          config
        );
        setOrganizations(response.data);
      } catch (error) {
        console.error("Error fetching organizations:", error);
      }
    };

    fetchOrganizations();
  }, []);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, shapefile: file || null });
    setFileUploaded(!!file); // Set fileUploaded to true if a file is selected
  };

  // Function to clear the error message after 3 seconds
  const clearErrorMessage = () => {
    setErrorMessage("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(""); // Clear any previous error message

    try {
      const token = localStorage.getItem("token");
      const data = new FormData();
      for (const key in formData) {
        if (key === "shapefile" && formData[key] === null) {
          continue; // Skip appending shapefile if it's null
        }
        data.append(key, formData[key]);
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/customers/projects/`,
        data,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        // Handle success here
        const newProject = response.data;
        setGeoJson(newProject.geojson); // Assume geojson is part of the response
        navigate(`/land-eligibility-analysis/${newProject.id}`);
      } else {
        // Handle error here
        setErrorMessage(
          "Failed to add project. Please check your details and try again."
        );

        // Clear the error message after 3 seconds
        setTimeout(() => {
          clearErrorMessage();
        }, 3000);
      }
    } catch (error) {
      setErrorMessage("Failed to add project"); // Set a generic error message

      // Optionally, display more specific error information if available:
      if (error.response && error.response.data && error.response.data.detail) {
        setErrorMessage(error.response.data.detail);
      }

      // Clear the error message after 3 seconds
      setTimeout(() => {
        clearErrorMessage();
      }, 3000);
    }
  };

  return (
    <div>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ maxWidth: 1000, margin: "auto" }}
      >
        <Typography
          variant="h4"
          sx={{
            mb: 2,
            fontWeight: "bold",
            textAlign: "center",
            color: "#0E3B43",
          }}
        >
          VM0047 Eligibility Check
        </Typography>
        {errorMessage && (
          <div style={{ color: "red", marginBottom: "10px" }}>
            {errorMessage}
          </div>
        )}
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              label="Project Name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Organization Name</InputLabel>
              <Select
                name="organization"
                value={formData.organization}
                label="Organization Name"
                onChange={handleInputChange}
              >
                {organizations.map((org) => (
                  <MenuItem key={org.id} value={org.id}>
                    {org.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Country"
              name="country"
              value={formData.country}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
          </Grid>
        </Grid>
        {useShapeFile && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
              mt: 2,
            }}
          >
            <label htmlFor="shapefile-upload">
              <Button
                component="span"
                variant="contained"
                startIcon={<CloudUploadIcon />}
              >
                Upload KML File
              </Button>
            </label>
            <input
              id="shapefile-upload"
              type="file"
              accept=".zip,.shp,.shx,.dbf,.kml"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            {fileUploaded && (
              <CheckCircleIcon
                color="success"
                sx={{ marginLeft: 1, fontSize: 30 }}
              />
            )}
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Button type="submit" variant="contained" color="primary">
            Analyse
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default GEELandEligibilityMap;
