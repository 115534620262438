import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Typography,
  Box,
  CircularProgress,
  List,
  ListItem,
} from "@mui/material";

const TiffTextDescriptionComponent = ({ projectId, selectedId }) => {
  const [descriptions, setDescriptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchDescriptions = async () => {
      setLoading(true);
      const token = localStorage.getItem("token"); // Fetch the token from localStorage

      if (!token) {
        setError("Authentication token not found.");
        setLoading(false);
        return; // Exit if no token is available
      }
      console.log("Project ID:", projectId); // Check what projectId is received
      console.log("Selected ID:", selectedId); // Check what selectedId is received

      try {
        const response = await axios.get(
          `http://127.0.0.1:8000/api/customers/projects/${projectId}/tiff/${selectedId}/descriptions/`,
          {
            headers: { Authorization: `Token ${token}` }, // Use token for API request
          }
        );
        setDescriptions(response.data); // Assume the response data is an array of descriptions
        setError("");
      } catch (err) {
        console.error("Failed to fetch data:", err);
        setError("Failed to fetch data");
        setDescriptions([]);
      } finally {
        setLoading(false);
      }
    };

    fetchDescriptions();
  }, [projectId, selectedId]);

  return (
    <Box sx={{ padding: 0.5 }}>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "530px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <List>
          {descriptions.length > 0 ? (
            descriptions.map((description, index) => (
              <ListItem key={index}>
                <Typography style={{ whiteSpace: "pre-line" }}>
                  {description.text}
                </Typography>
              </ListItem>
            ))
          ) : (
            <Typography>No descriptions available.</Typography>
          )}
        </List>
      )}
    </Box>
  );
};

export default TiffTextDescriptionComponent;
