import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Container,
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd"; // Import the icon

function Register() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [isDemoUser, setIsDemoUser] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/customers/register/`,
        { username, email, password, is_demo_user: isDemoUser },
        { headers: { "Content-Type": "application/json" } }
      );
      navigate("/login");
    } catch (error) {
      console.error("Registration Error:", error.response || error);
      if (error.response && error.response.data) {
        // Check if the error is specifically about the username
        if (error.response.data.username) {
          setError("A user with that username already exists.");
        } else {
          // Handle other errors
          setError(error.response.data.detail || "Registration failed!");
        }
      } else {
        setError("Registration failed!");
      }
    }
  };

  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5" gutterBottom>
          <PersonAddIcon
            sx={{
              mr: 1,
              verticalAlign: "middle",
              fontSize: 40,
              color: "#70C29C", // Set the color of the icon
            }}
          />
          SIGN UP
        </Typography>
        {/* Display any error message here */}
        {error && <Typography color="error">{error}</Typography>}
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {/* ... other form fields ... */}
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={isDemoUser}
                onChange={(e) => setIsDemoUser(e.target.checked)}
              />
            }
            label="Sign up as Demo Account"
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              mt: 3,
              mb: 2,
              backgroundColor: "#70C29C", // Set the button color to green
              "&:hover": {
                backgroundColor: "#0E3B43", // Optional: Change color on hover
              },
            }}
          >
            SIGN UP
          </Button>
          <Button
            fullWidth
            sx={{
              mt: 3,
              mb: 2,
              backgroundColor: "#0E3B43", // Custom color
              color: "#FFF6EB", // Text color (optional, change as needed)
              "&:hover": {
                backgroundColor: "#0b2a33", // Darker shade for hover state (optional)
              },
            }}
            onClick={() => navigate("/login")}
          >
            Allready have account? Login
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
export default Register;
