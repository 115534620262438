import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { IconButton, Box } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const LeafletMap = () => {
  const { projectId, token: urlToken } = useParams();
  const location = useLocation();
  const { latitude, longitude } = location.state || {
    latitude: 37.5, // Default latitude
    longitude: 71.0, // Default longitude
  };
  const [tiffData, setTiffData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTiffData = async () => {
      const token = localStorage.getItem("token") || urlToken;

      if (!token) {
        console.error("Token not found. Redirect to the login page.");
        return;
      }

      try {
        const config = {
          headers: {
            Authorization: `Token ${token}`,
          },
        };
        const apiUrl = `http://127.0.0.1:8000/api/customers/projects/${projectId}/tiff/`;
        const response = await axios.get(apiUrl, config);

        if (response.status === 200) {
          setTiffData(response.data);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching TIFF data:", error);
      }
    };

    fetchTiffData();
  }, [projectId, urlToken]);

  useEffect(() => {
    if (!isLoading) {
      const map = L.map("map").setView([latitude, longitude], 14);

      // OpenStreetMap layer
      var openStreetMapLayer = L.tileLayer(
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        {
          attribution:
            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        }
      );

      // Esri World Imagery layer
      var esriWorldImageryLayer = L.tileLayer(
        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
        {
          attribution:
            "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
        }
      );

      // Add OpenStreetMap as the default layer
      map.addLayer(openStreetMapLayer);

      const overlayMaps = {}; // Initialize an empty object for overlay maps

      // Dynamically adding TIFF WMS layers
      tiffData.forEach((tiff, index) => {
        const layer = L.tileLayer.wms(
          "http://localhost:8080/geoserver/geoapp/wms",
          {
            layers: tiff.name,
            format: "image/png",
            transparent: true,
            version: "1.1.0",
          }
        );

        // Add the first TIFF layer to the map directly, others just to the overlayMaps object
        if (index === 0) {
          layer.addTo(map); // This makes the first layer visible by default
        }

        // Add all layers to the overlayMaps object for the layers control
        overlayMaps[tiff.name] = layer;
      });

      // Base layers for layer control
      var baseLayers = {
        OpenStreetMap: openStreetMapLayer,
        "Esri World Imagery": esriWorldImageryLayer,
      };

      // Adding layer control to toggle between layers
      L.control
        .layers(baseLayers, overlayMaps, { collapsed: false })
        .addTo(map);

      // Clean up function
      return () => {
        map.remove();
      };
    }
  }, [isLoading, tiffData, latitude, longitude]);

  return (
    <Box sx={{ position: "relative", width: "100%", height: "750px" }}>
      <IconButton
        component={Link}
        to={`/project-details/${projectId}`}
        sx={{
          position: "absolute",
          top: 10,
          left: 60,
          zIndex: 500,
          backgroundColor: "#fff",
          "&:hover": {
            backgroundColor: "#f5f5f5",
          },
        }}
      >
        <ArrowBackIosNewIcon />
      </IconButton>
      <div id="map" style={{ width: "100%", height: "100%" }}></div>
    </Box>
  );
};

export default LeafletMap;
