// ProjectMarker.js
import React from "react";
import { Marker, Popup } from "react-leaflet";

const ProjectMarker = ({ project, customIcon }) => {
  return (
    <Marker position={[project.latitude, project.longitude]} icon={customIcon}>
      <Popup>
        <strong>{project.name}</strong>
        <br />
        Country: {project.country}
        <br />
        Category: {project.category}
        <br />
        Status: {project.status}
        <br />
        Area (ha): {project.area_ha}
        <br />
        Net Emission Reduction: {project.net_emission_reduction} tCO2e
        <br />
        Landowners: {project.landowner_count}
      </Popup>
    </Marker>
  );
};

export default ProjectMarker;
