import React, { useState, useEffect } from "react";
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ThemeProvider, createTheme, CssBaseline } from "@mui/material";
import Home from "./Components/Organisation/Home";
import Header from "./Components/Organisation/Header"; // Import the Header component
import Register from "./Components/Organisation/Register";
import Login from "./Components/Organisation/Login";
import Logout from "./Components/Organisation/Logout";
import ProjectDetails from "./Components/Organisation/ProjectDetails"; // Import the Header component
import AddProject from "./Components/Organisation/AddProject";
import EditProject from "./Components/Organisation/EditProject";
import RegisterOrganisation from "./Components/Organisation/RegisterOrganisation";
import GeoJsonUpload from "./Components/Analysis/GeoJsonUpload";
import Report from "./Components/Analysis/Report";
import Dashboard from "./Components/Analysis/Dashboard"; // Import the Dashboard component
import AnalyticsDashboard from "./Components/Analysis/AnalyticsDashboard"; // Import the Dashboard component
import CustomImageOverlay from "./Components/Analysis/CustomImageOverlay"; // Import the Dashboard component
import TileMap from "./Components/Analysis/TileMap";
import ImageOverlayMap from "./Components/Analysis/ImageOverlayMap";
import LeafletMap from "./Components/Analysis/LeafletMap";
import LeafletMapSidebar from "./Components/Analysis/LeafletMapSidebar";
import GEEGlobalForestChange from "./Components/Analysis/GEEGlobalForestChange";
import GEEElevation30m from "./Components/Analysis/GEEElevantion30m";
import GEELandClassificationMap10m from "./Components/Analysis/GEELandClassificationMap10m";
import EligibilityCheck from "./Components/Organisation/EligibilityCheck";
import GEELandEligibilityMap from "./Components/Analysis/GEELandEligibilityMap";
import LandEligibilityAnalysis from "./Components/Analysis/LandEligibilityAnalysis";

// Create a theme instance.
const theme = createTheme({
  // theme settings
});

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); // Add a loading state

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsAuthenticated(!!token);
    setLoading(false); // Set loading to false after checking the token
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Or any other loading indicator
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <div>
          {/* Include the Header component */}
          <Header />
          <main style={{ padding: "0px" }}>
            <Routes>
              <Route
                path="/"
                element={isAuthenticated ? <Home /> : <Navigate to="/login" />}
              />
              <Route
                path="/project-details/:projectId"
                element={
                  isAuthenticated ? <ProjectDetails /> : <Navigate to="/" />
                }
              />

              <Route
                path="/land-eligibility-analysis/:projectId"
                element={<LandEligibilityAnalysis />}
              />

              <Route
                path="/project-details/:projectId/upload-geojson"
                element={
                  isAuthenticated ? (
                    <GeoJsonUpload />
                  ) : (
                    <Navigate to="/upload-geojson" />
                  )
                }
              />
              <Route
                path="/project-details/upload-geojson"
                element={
                  isAuthenticated ? <GeoJsonUpload /> : <Navigate to="/" />
                }
              />
              <Route
                path="/project-details/report"
                element={isAuthenticated ? <Report /> : <Navigate to="/" />}
              />
              <Route
                path="/register-organization"
                element={
                  isAuthenticated ? (
                    <RegisterOrganisation />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/eligibility-check"
                element={
                  isAuthenticated ? <EligibilityCheck /> : <Navigate to="/" />
                }
              />
              <Route
                path="/add-project"
                element={isAuthenticated ? <AddProject /> : <Navigate to="/" />}
              />
              <Route
                path="/projects/:projectId/edit"
                element={
                  isAuthenticated ? <EditProject /> : <Navigate to="/" />
                }
              />
              <Route
                path="/dashboard"
                element={isAuthenticated ? <Dashboard /> : <Navigate to="/" />}
              />
              <Route
                path="/analytics-dashboard"
                element={
                  isAuthenticated ? <AnalyticsDashboard /> : <Navigate to="/" />
                }
              />
              <Route
                path="/image-overlay"
                element={
                  isAuthenticated ? <CustomImageOverlay /> : <Navigate to="/" />
                }
              />
              <Route
                path="/tilemap"
                element={isAuthenticated ? <TileMap /> : <Navigate to="/" />}
              />
              <Route
                path="/imageoverlaymap"
                element={
                  isAuthenticated ? <ImageOverlayMap /> : <Navigate to="/" />
                }
              />
              <Route
                path="/leafletmap/:projectId/:token"
                element={isAuthenticated ? <LeafletMap /> : <Navigate to="/" />}
              />
              <Route
                path="/leafletmap-sidebar/:projectId/:token"
                element={
                  isAuthenticated ? <LeafletMapSidebar /> : <Navigate to="/" />
                }
              />
              <Route
                path="/gee-deforestation-data"
                element={
                  isAuthenticated ? (
                    <GEEGlobalForestChange />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/gee-elevation"
                element={
                  isAuthenticated ? <GEEElevation30m /> : <Navigate to="/" />
                }
              />
              <Route
                path="/land-eligibility"
                element={
                  isAuthenticated ? (
                    <GEELandEligibilityMap />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/gee-land-classification"
                element={
                  isAuthenticated ? (
                    <GEELandClassificationMap10m />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route path="/register" element={<Register />} />
              <Route
                path="/login"
                element={<Login setIsAuthenticated={setIsAuthenticated} />}
              />
              <Route
                path="/logout"
                element={<Logout setIsAuthenticated={setIsAuthenticated} />}
              />
            </Routes>
          </main>
          {/* Include the Footer component */}
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
