import React, { useState, useEffect } from "react";
import axios from "axios";
import WaterCreditIcon from "../Assets/Mapicons/icons8-water-64.png";
import CarbonCreditIcon from "../Assets/Mapicons/icons8-carbon-credit-64-1.png";
import BiodiversityIcon from "../Assets/Mapicons/icons8-biodiversity-100.png";
import ProjectManagementIcon from "../Assets/Mapicons/icons8-project.gif";
import ProjectProgressIcon from "../Assets/Mapicons/icons8-in-progress-96.png";
import ReportIcon from "../Assets/Mapicons/icons8-report-64.png";
import CircularProgress from "@mui/material/CircularProgress";
import VisibilityIcon from "@mui/icons-material/Visibility";
import GetAppIcon from "@mui/icons-material/GetApp";
import {
  Typography,
  Button,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stepper,
  Step,
  StepLabel,
  Grid,
} from "@mui/material";

const projectProgressSteps = [
  "Participatory Appraisal",
  "Stakeholder Consultation",
  "Contract Gathering",
  "ID of Beneficiaries",
  "Feasibility Assessment",
  "Pre-Financing",
];
const handleView = (url) => {
  if (url) {
    window.open(url, "_blank"); // Open the URL in a new tab/window for viewing
  } else {
    console.log("No document URL provided for viewing.");
  }
};

const handleDownload = (url) => {
  if (url) {
    // Initiate file download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", ""); // The download attribute can be left empty to use the filename from the URL
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } else {
    console.log("No document URL provided for downloading.");
  }
};

const ProjectInformation = ({ projectId, token }) => {
  const [waterData, setWaterData] = useState([]);
  const [carbonData, setCarbonData] = useState([]);
  const [biodiversityData, setBiodiversityData] = useState([]);
  const [report, setReportData] = useState([]);
  const [project, setProject] = useState(null);
  const [shape_and_kml_to_geojsonData, setShapeKmlGeojsonData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const projectResponse = await axios.get(
          `http://127.0.0.1:8000/api/customers/projects/${projectId}/`,
          {
            headers: { Authorization: `Token ${token}` },
          }
        );

        if (projectResponse.status < 200 || projectResponse.status >= 300) {
          throw new Error("Error fetching project details");
        }

        const projectData = projectResponse.data;
        setProject(projectData);
        setWaterData(projectData.water_data);
        setCarbonData(projectData.carbon_data);
        setBiodiversityData(projectData.biodiversity_data);
        setReportData(projectData.report);

        const shape_and_kml_to_geojsonResponse = await axios.get(
          `http://127.0.0.1:8000/api/customers/projects/${projectId}/fetch_file`
        );

        const shape_and_kml_to_geojsonData =
          shape_and_kml_to_geojsonResponse.data.data;
        setShapeKmlGeojsonData(shape_and_kml_to_geojsonData);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [projectId, token]);

  // Find the active step index based on project progress
  const activeStep = project
    ? projectProgressSteps.indexOf(project.project_progress)
    : 0;

  if (!project) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      {/* PROJECT SUMMARY */}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <img
          src={ProjectManagementIcon}
          alt="Project Management Icon"
          style={{
            width: "60px",
            height: "60px", // Set the height to match the width to create a circle
            marginRight: "15px",
            borderRadius: "50%", // Add border-radius to create a circular shape
          }}
        />
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Project Summary
        </Typography>
      </Box>
      <div className="project-summary">
        {/* First "Project Area" */}
        {project ? (
          <div className="summary-item">
            <label
              style={{
                textAlign: "center", // Center align the label #0E3B43, #70C29C
                color: "#0E3B43", // Set the color to #0E3B43
              }}
            >
              Project Area
            </label>

            <div
              style={{
                textAlign: "center",
                fontSize: "30px",
                fontWeight: "bold",
                color: "#70C29C", // Set the color to #0E3B43
              }}
            >
              {`${project.area_ha}`}{" "}
              <span
                style={{
                  fontSize: "16px", // Adjust the font size as needed
                  fontStyle: "normal",
                  color: "#0E3B43", // Set the color to #0E3B43
                }}
              >
                ha
              </span>
            </div>
          </div>
        ) : (
          <div className="summary-item">
            <label>Project Area</label>
            <div>-</div>
          </div>
        )}

        {/* landowner_count "Project Area" */}
        {project ? (
          <div className="summary-item">
            <label
              style={{
                textAlign: "center", // Center align the label #0E3B43, #70C29C
                color: "#0E3B43", // Set the color to #0E3B43
              }}
            >
              Number of Landowners
            </label>

            <div
              style={{
                textAlign: "center",
                fontSize: "30px",
                fontWeight: "bold",
                color: "#70C29C", // Set the color to #0E3B43
              }}
            >
              {`${project.landowner_count}`}{" "}
              <span
                style={{
                  fontSize: "16px", // Adjust the font size as needed
                  fontStyle: "normal",
                  color: "#0E3B43", // Set the color to #0E3B43
                }}
              ></span>
            </div>
          </div>
        ) : (
          <div className="summary-item">
            <label> Number of Landowners</label>
            <div>-</div>
          </div>
        )}

        {/* net_emission_reduction "Project Area" */}
        {project ? (
          <div className="summary-item">
            <label
              style={{
                textAlign: "center", // Center align the label #0E3B43, #70C29C
                color: "#0E3B43", // Set the color to #0E3B43
              }}
            >
              Net Emission Reduction
            </label>

            <div
              style={{
                textAlign: "center",
                fontSize: "30px",
                fontWeight: "bold",
                color: "#70C29C", // Set the color to #0E3B43
              }}
            >
              {`${project.net_emission_reduction}`}{" "}
              <span
                style={{
                  fontSize: "16px", // Adjust the font size as needed
                  fontStyle: "normal",
                  color: "#0E3B43", // Set the color to #0E3B43
                }}
              >
                tCO2e/yr
              </span>
            </div>
          </div>
        ) : (
          <div className="summary-item">
            <label>Net Emission Reduction</label>
            <div>-</div>
          </div>
        )}

        {/* Fourth "Project Area" */}
        {project ? (
          <div className="summary-item">
            <label
              style={{
                textAlign: "center", // Center align the label #0E3B43, #70C29C
                color: "#0E3B43", // Set the color to #0E3B43
              }}
            >
              Project Status
            </label>

            <div
              style={{
                textAlign: "center",
                fontSize: "28px",
                fontWeight: "bold",
                color: "#70C29C", // Set the color to #0E3B43
              }}
            >
              {`${project.project_progress}`}{" "}
              <span
                style={{
                  fontSize: "16px", // Adjust the font size as needed
                  fontStyle: "normal",
                  color: "#0E3B43", // Set the color to #0E3B43
                }}
              ></span>
            </div>
          </div>
        ) : (
          <div className="summary-item">
            <label>Project Status</label>
            <div>-</div>
          </div>
        )}
      </div>

      {/* CARBON */}
      {/* <Box sx={{ display: "flex", alignItems: "center" }}>
        <img
          src={CarbonCreditIcon}
          alt="Carbon Icon"
          style={{
            width: "60px",
            height: "60px", // Set the height to match the width to create a circle
            marginRight: "15px",
            borderRadius: "50%", // Add border-radius to create a circular shape
          }}
        />
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Carbon
        </Typography>
      </Box>
      <div className="project-summary">
        {carbonData.map((carbonItem) => (
          <div className="summary-item" key={carbonItem.id}>
            <label
              style={{
                textAlign: "center", // Center align the label #0E3B43, #70C29C
                color: "#0E3B43", // Set the color to #0E3B43
              }}
            >
              Above Ground Biomass
            </label>
            <div
              style={{
                textAlign: "center",
                fontSize: "30px",
                fontWeight: "bold",
                color: "#70C29C", // Set the color to #0E3B43
              }}
            >
              {`${carbonItem.above_ground_biomass}`}{" "}
              <span
                style={{
                  fontSize: "16px", // Adjust the font size as needed
                  fontStyle: "normal",
                  color: "#0E3B43", // Set the color to #0E3B43
                }}
              >
                tCO2e
              </span>
            </div>
          </div>
        ))}

        {carbonData.length === 0 && (
          <div className="summary-item">
            <label
              style={{
                textAlign: "center", // Center align the label #0E3B43, #70C29C
                color: "#0E3B43", // Set the color to #0E3B43
              }}
            >
              Above Ground Biomass
            </label>

            <div>-</div>
          </div>
        )}

        {carbonData.map((carbonItem) => (
          <div className="summary-item" key={carbonItem.id}>
            <label
              style={{
                textAlign: "center", // Center align the label
              }}
            >
              Above Ground Biomass Potential
            </label>
            <div
              style={{
                textAlign: "center",
                fontSize: "30px",
                fontWeight: "bold",
                color: "#70C29C", // Set the color to #0E3B43
              }}
            >
              {`${carbonItem.above_ground_biomass_potential}`}{" "}
              <span
                style={{
                  fontSize: "16px", // Adjust the font size as needed
                  fontStyle: "normal",
                  color: "#0E3B43", // Set the color to #0E3B43
                }}
              ></span>
            </div>
          </div>
        ))}
        {carbonData.length === 0 && (
          <div className="summary-item">
            <label
              style={{
                textAlign: "center", // Center align the label #0E3B43, #70C29C
                color: "#0E3B43", // Set the color to #0E3B43
              }}
            >
              Above Ground Biomass Potential
            </label>
            <div>-</div>
          </div>
        )}

        {carbonData.map((carbonItem) => (
          <div className="summary-item" key={carbonItem.id}>
            <label
              style={{
                textAlign: "center", // Center align the label
              }}
            >
              -
            </label>
            <div
              style={{
                textAlign: "center",
                fontSize: "30px",
                fontWeight: "bold",
                color: "#70C29C", // Set the color to #0E3B43
              }}
            >
              {``}{" "}
              <span
                style={{
                  fontSize: "16px", // Adjust the font size as needed
                  fontStyle: "normal",
                  color: "#0E3B43", // Set the color to #0E3B43
                }}
              ></span>
            </div>
          </div>
        ))}
        {carbonData.length === 0 && (
          <div className="summary-item">
            <label
              style={{
                textAlign: "center", // Center align the label #0E3B43, #70C29C
                color: "#0E3B43", // Set the color to #0E3B43
              }}
            >
              -
            </label>
            <div>-</div>
          </div>
        )}

        {carbonData.map((carbonItem) => (
          <div className="summary-item" key={carbonItem.id}>
            <label
              style={{
                textAlign: "center", // Center align the label
              }}
            >
              -
            </label>
            <div
              style={{
                textAlign: "center",
                fontSize: "30px",
                fontWeight: "bold",
                color: "#70C29C", // Set the color to #0E3B43
              }}
            >
              {``}{" "}
              <span
                style={{
                  fontSize: "16px", // Adjust the font size as needed
                  fontStyle: "normal",
                  color: "#0E3B43", // Set the color to #0E3B43
                }}
              ></span>
            </div>
          </div>
        ))}
        {carbonData.length === 0 && (
          <div className="summary-item">
            <label
              style={{
                textAlign: "center", // Center align the label #0E3B43, #70C29C
                color: "#0E3B43", // Set the color to #0E3B43
              }}
            >
              -
            </label>
            <div>-</div>
          </div>
        )}
      </div> */}

      {/* BIODIVERSITY */}
      {/* <Box sx={{ display: "flex", alignItems: "center" }}>
        <img
          src={BiodiversityIcon}
          alt="Biodiversity Icon"
          style={{
            width: "60px",
            height: "60px", // Set the height to match the width to create a circle
            marginRight: "15px",
            borderRadius: "50%", // Add border-radius to create a circular shape
          }}
        />
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Biodiversity
        </Typography>
      </Box>
      <div className="project-summary">
        {biodiversityData.map((biodiversityItem) => (
          <div className="summary-item" key={biodiversityItem.id}>
            <label
              style={{
                textAlign: "center", // Center align the label #0E3B43, #70C29C
                color: "#0E3B43", // Set the color to #0E3B43
              }}
            >
              Number of Red List Species
            </label>

            <div
              style={{
                textAlign: "center",
                fontSize: "30px",
                fontWeight: "bold",
                color: "#70C29C", // Set the color to #0E3B43
              }}
            >
              {`${biodiversityItem.num_red_list_species}`}{" "}
              <span
                style={{
                  fontSize: "16px", // Adjust the font size as needed
                  fontStyle: "normal",
                  color: "#0E3B43", // Set the color to #0E3B43
                }}
              ></span>
            </div>
          </div>
        ))}

        {biodiversityData.length === 0 && (
          <div className="summary-item">
            <label
              style={{
                textAlign: "center", // Center align the label #0E3B43, #70C29C
                color: "#0E3B43", // Set the color to #0E3B43
              }}
            >
              Number of Red List Species
            </label>
            <div>-</div>
          </div>
        )}

        {biodiversityData.map((biodiversityItem) => (
          <div className="summary-item" key={biodiversityItem.id}>
            <label
              style={{
                textAlign: "center", // Center align the label #0E3B43, #70C29C
                color: "#0E3B43", // Set the color to #0E3B43
              }}
            >
              Nearest Key Biodiversity Area
            </label>

            <div
              style={{
                textAlign: "center",
                fontSize: "30px",
                fontWeight: "bold",
                color: "#70C29C", // Set the color to #0E3B43
              }}
            >
              {`${biodiversityItem.nearest_key_biodiversity_areas}`}{" "}
              <span
                style={{
                  fontSize: "16px", // Adjust the font size as needed
                  fontStyle: "normal",
                  color: "#0E3B43", // Set the color to #0E3B43
                }}
              >
                Km
              </span>
            </div>
          </div>
        ))}

        {biodiversityData.length === 0 && (
          <div className="summary-item">
            <label
              style={{
                textAlign: "center", // Center align the label #0E3B43, #70C29C
                color: "#0E3B43", // Set the color to #0E3B43
              }}
            >
              Nearest Key Biodiversity Area
            </label>
            <div>-</div>
          </div>
        )}

        {biodiversityData.map((biodiversityItem) => (
          <div className="summary-item" key={biodiversityItem.id}>
            <label
              style={{
                textAlign: "center", // Center align the label #0E3B43, #70C29C
                color: "#0E3B43", // Set the color to #0E3B43
              }}
            >
              Nearest Protected Area
            </label>

            <div
              style={{
                textAlign: "center",
                fontSize: "30px",
                fontWeight: "bold",
                color: "#70C29C", // Set the color to #0E3B43
              }}
            >
              {`${biodiversityItem.nearest_protected_areas}`}{" "}
              <span
                style={{
                  fontSize: "16px", // Adjust the font size as needed
                  fontStyle: "normal",
                  color: "#0E3B43", // Set the color to #0E3B43
                }}
              >
                Km
              </span>
            </div>
          </div>
        ))}

        {biodiversityData.length === 0 && (
          <div className="summary-item">
            <label
              style={{
                textAlign: "center", // Center align the label #0E3B43, #70C29C
                color: "#0E3B43", // Set the color to #0E3B43
              }}
            >
              Nearest Protected Area
            </label>
            <div>-</div>
          </div>
        )}

        {biodiversityData.map((biodiversityItem) => (
          <div className="summary-item" key={biodiversityItem.id}>
            <label
              style={{
                textAlign: "center", // Center align the label #0E3B43, #70C29C
                color: "#0E3B43", // Set the color to #0E3B43
              }}
            >
              Habitat Diversity
            </label>

            <div
              style={{
                textAlign: "center",
                fontSize: "30px",
                fontWeight: "bold",
                color: "#70C29C", // Set the color to #0E3B43
              }}
            >
              {`${biodiversityItem.habitat_diversity}`}{" "}
              <span
                style={{
                  fontSize: "16px", // Adjust the font size as needed
                  fontStyle: "normal",
                  color: "#0E3B43", // Set the color to #0E3B43
                }}
              ></span>
            </div>
          </div>
        ))}

        {biodiversityData.length === 0 && (
          <div className="summary-item">
            <label
              style={{
                textAlign: "center", // Center align the label #0E3B43, #70C29C
                color: "#0E3B43", // Set the color to #0E3B43
              }}
            >
              Habitat Diversity
            </label>
            <div>-</div>
          </div>
        )}
      </div> */}

      {/* WATER */}
      {/* <Box sx={{ display: "flex", alignItems: "center" }}>
        <img
          src={WaterCreditIcon}
          alt="Water Icon"
          style={{
            width: "60px",
            height: "60px", // Set the height to match the width to create a circle
            marginRight: "15px",
            borderRadius: "50%", // Add border-radius to create a circular shape
          }}
        />
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Water
        </Typography>
      </Box>
      <div className="project-summary">
        {waterData.map((waterItem) => (
          <div className="summary-item" key={waterItem.id}>
            <label
              style={{
                textAlign: "center", // Center align the label #0E3B43, #70C29C
                color: "#0E3B43", // Set the color to #0E3B43
              }}
            >
              Water Consumption
            </label>
            <div
              style={{
                textAlign: "center",
                fontSize: "30px",
                fontWeight: "bold",
                color: "#70C29C", // Set the color to #0E3B43
              }}
            >
              {`${waterItem.water_consumption}`}{" "}
              <span
                style={{
                  fontSize: "16px", // Adjust the font size as needed
                  fontStyle: "normal",
                  color: "#0E3B43", // Set the color to #0E3B43
                }}
              >
                cubic meters (m3)
              </span>
            </div>
          </div>
        ))}

        {waterData.length === 0 && (
          <div className="summary-item">
            <label
              style={{
                textAlign: "center", // Center align the label #0E3B43, #70C29C
                color: "#0E3B43", // Set the color to #0E3B43
              }}
            >
              Water Sources
            </label>
            <div>-</div>
          </div>
        )}

        {waterData.map((waterItem) => (
          <div className="summary-item" key={waterItem.id}>
            <label
              style={{
                textAlign: "center", // Center align the label
              }}
            >
              Water Sources
            </label>
            <div
              style={{
                textAlign: "center",
                fontSize: "30px",
                fontWeight: "bold",
                color: "#70C29C", // Set the color to #0E3B43
              }}
            >
              {`${waterItem.water_sources}`}{" "}
              <span
                style={{
                  fontSize: "16px", // Adjust the font size as needed
                  fontStyle: "normal",
                  color: "#0E3B43", // Set the color to #0E3B43
                }}
              ></span>
            </div>
          </div>
        ))}
        {waterData.length === 0 && (
          <div className="summary-item">
            <label
              style={{
                textAlign: "center", // Center align the label #0E3B43, #70C29C
                color: "#0E3B43", // Set the color to #0E3B43
              }}
            >
              Water Sources
            </label>
            <div>-</div>
          </div>
        )}

        {waterData.map((waterItem) => (
          <div className="summary-item" key={waterItem.id}>
            <label
              style={{
                textAlign: "center", // Center align the label
              }}
            >
              Water Quality
            </label>
            <div
              style={{
                textAlign: "center",
                fontSize: "30px",
                fontWeight: "bold",
                color: "#70C29C", // Set the color to #0E3B43
              }}
            >
              {`${waterItem.water_quality}`}{" "}
              <span
                style={{
                  fontSize: "16px", // Adjust the font size as needed
                  fontStyle: "normal",
                  color: "#0E3B43", // Set the color to #0E3B43
                }}
              ></span>
            </div>
          </div>
        ))}
        {waterData.length === 0 && (
          <div className="summary-item">
            <label
              style={{
                textAlign: "center", // Center align the label #0E3B43, #70C29C
                color: "#0E3B43", // Set the color to #0E3B43
              }}
            >
              Water Quality
            </label>
            <div>-</div>
          </div>
        )}

        {waterData.map((waterItem) => (
          <div className="summary-item" key={waterItem.id}>
            <label
              style={{
                textAlign: "center", // Center align the label
              }}
            >
              -
            </label>
            <div
              style={{
                textAlign: "center",
                fontSize: "30px",
                fontWeight: "bold",
                color: "#70C29C", // Set the color to #0E3B43
              }}
            >
              {``}{" "}
              <span
                style={{
                  fontSize: "16px", // Adjust the font size as needed
                  fontStyle: "normal",
                  color: "#0E3B43", // Set the color to #0E3B43
                }}
              ></span>
            </div>
          </div>
        ))}
        {waterData.length === 0 && (
          <div className="summary-item">
            <label
              style={{
                textAlign: "center", // Center align the label #0E3B43, #70C29C
                color: "#0E3B43", // Set the color to #0E3B43
              }}
            >
              -
            </label>
            <div>-</div>
          </div>
        )}
      </div> */}

      {/* PROJECT PROGRESS */}
      <Grid container spacing={2}>
        <Grid item xs={3}>
          {" "}
          {/* Adjust the width as needed */}
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                src={ProjectProgressIcon}
                alt="Project Progress"
                style={{
                  width: "60px",
                  height: "60px", // Set the height to match the width to create a circle
                  marginRight: "15px",
                  borderRadius: "50%", // Add border-radius to create a circular shape
                }}
              />
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "#0E3B43" }}
              >
                Project Progress
              </Typography>
            </Box>

            <Stepper activeStep={activeStep} orientation="vertical">
              {projectProgressSteps.map((step, index) => (
                <Step key={step} completed={index < activeStep}>
                  <StepLabel>{step}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {/* <Button sx={{ marginTop: 2 }}>More →</Button> */}
          </Paper>
        </Grid>

        {/*AVILABLE REPORTS */}
        <Grid item xs={9}>
          {" "}
          {/* Adjust the width as needed */}
          <Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                src={ReportIcon}
                alt="Report Icon"
                style={{
                  width: "60px",
                  height: "60px", // Set the height to match the width to create a circle
                  marginRight: "15px",
                  borderRadius: "50%", // Add border-radius to create a circular shape
                }}
              />
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Available Reports
              </Typography>
            </Box>
            <br />
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow style={{ fontWeight: "bold", color: "#F5CB5C" }}>
                    <TableCell>
                      <Typography
                        variant="subtitle1"
                        component="div"
                        style={{ fontWeight: "bold" }}
                      >
                        Report name
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle1"
                        component="div"
                        style={{ fontWeight: "bold" }}
                      >
                        Description
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle1"
                        component="div"
                        style={{ fontWeight: "bold" }}
                      >
                        Price
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle1"
                        component="div"
                        style={{ fontWeight: "bold" }}
                      >
                        Actions
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {project.report.map((reportItem, index) => (
                    <TableRow key={reportItem.id}>
                      <TableCell>
                        <span style={{ fontWeight: "bold", color: "#513B3C" }}>
                          {reportItem.report_name}
                        </span>
                      </TableCell>

                      <TableCell>{reportItem.report_description}</TableCell>
                      <TableCell>{`$${reportItem.report_price}`}</TableCell>
                      <TableCell>
                        <Button
                          onClick={() => {
                            if (reportItem.report_action === "View") {
                              handleView(reportItem.view_document_url);
                            } else {
                              handleDownload(reportItem.download_document_url);
                            }
                          }}
                          startIcon={
                            reportItem.report_action === "View" ? (
                              <VisibilityIcon />
                            ) : (
                              <GetAppIcon />
                            )
                          }
                          disabled={
                            !reportItem.view_document_url &&
                            !reportItem.download_document_url
                          }
                        >
                          {reportItem.report_action}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                  {Array.from({ length: 5 - project.report.length }).map(
                    (_, index) => (
                      <TableRow key={`empty-${index}`}>
                        <TableCell colSpan={4} style={{ padding: "20px" }}>
                          {/* Empty cell with increased spacing */}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProjectInformation;
