import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  TextField,
  Button,
  Snackbar,
  Box,
} from "@mui/material";

const RegisterOrganisation = () => {
  const [formData, setFormData] = useState({
    name: "",
    country: "",
  });

  const [successResponse, setSuccessResponse] = useState(null);
  const [errorResponse, setErrorResponse] = useState(null);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Get the token from localStorage or your preferred storage mechanism
      const token = localStorage.getItem("token");

      // Set the headers to include the token for authentication
      const headers = {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/customers/organizations/register-organization/`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setSuccessResponse(data);
        setErrorResponse("");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        const data = await response.json();
        setErrorResponse(data);
      }
    } catch (error) {
      console.error("Error registering organization:", error);
      setErrorResponse({ error: "An error occurred. Please try again later." });
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
        flexDirection="column"
      >
        <Typography variant="h4" gutterBottom>
          Register Organisation
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Organisation Name"
            name="name"
            fullWidth
            required
            onChange={handleInputChange}
          />
          <TextField
            label="Organisation Country"
            name="country"
            fullWidth
            required
            onChange={handleInputChange}
          />
          <Box mt={2} display="flex" justifyContent="center">
            <Button type="submit" variant="contained" color="secondary">
              Register
            </Button>
          </Box>
        </form>

        <Snackbar
          open={!!successResponse}
          message="Organization registered successfully"
          autoHideDuration={5000}
          onClose={() => setSuccessResponse(null)}
        />

        <Snackbar
          open={!!errorResponse}
          message={errorResponse?.error || "An error occurred"}
          autoHideDuration={5000}
          onClose={() => setErrorResponse(null)}
        />
      </Box>
    </Container>
  );
};

export default RegisterOrganisation;
