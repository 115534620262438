import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const AddProject = () => {
  const [formData, setFormData] = useState({
    name: "",
    country: "",
    category: "",
    status: "",
    project_progress: "",
    area_ha: 0, // Default value set to 0
    net_emission_reduction: 0, // Default value set to 0
    latitude: 0, // Default value set to 0
    longitude: 0, // Default value set to 0
    organization: "",
  });

  const [useShapeFile, setUseShapeFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const token = localStorage.getItem("token");
        const config = {
          headers: {
            Authorization: `Token ${token}`,
          },
        };
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/customers/organizations/`,
          config
        );
        setOrganizations(response.data);
      } catch (error) {
        console.error("Error fetching organizations:", error);
      }
    };

    fetchOrganizations();
  }, []);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, shapefile: file || null });
  };

  // Function to clear the error message after 3 seconds
  const clearErrorMessage = () => {
    setErrorMessage("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(""); // Clear any previous error message

    try {
      const token = localStorage.getItem("token");
      const data = new FormData();
      for (const key in formData) {
        if (key === "shapefile" && formData[key] === null) {
          continue; // Skip appending shapefile if it's null
        }
        data.append(key, formData[key]);
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/customers/projects/`,
        data,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        // Handle success here
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        // Handle error here
        setErrorMessage(
          "Failed to add project. Please check your details and try again."
        );

        // Clear the error message after 3 seconds
        setTimeout(() => {
          clearErrorMessage();
        }, 3000);
      }
    } catch (error) {
      setErrorMessage("Failed to add project"); // Set a generic error message

      // Optionally, display more specific error information if available:
      if (error.response && error.response.data && error.response.data.detail) {
        setErrorMessage(error.response.data.detail);
      }

      // Clear the error message after 3 seconds
      setTimeout(() => {
        clearErrorMessage();
      }, 3000);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ maxWidth: 500, margin: "auto" }}
    >
      <Typography
        variant="h4"
        sx={{
          mb: 2,
          fontWeight: "bold",
          textAlign: "center",
          color: "#OE3B43",
        }}
      >
        Add New Project
      </Typography>
      {errorMessage && ( // Display the error message conditionally
        <div style={{ color: "red", marginBottom: "10px" }}>{errorMessage}</div>
      )}
      {useShapeFile ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 2,
          }}
        >
          <label htmlFor="shapefile-upload">
            <Button
              component="span"
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              Upload shapefile
            </Button>
          </label>
          <input
            id="shapefile-upload"
            type="file"
            accept=".zip,.shp,.shx,.dbf,.kml"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: 2,
            }}
          ></Box>
          <TextField
            label="Project Name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Organization Name</InputLabel>
            <Select
              name="organization"
              value={formData.organization}
              label="Organization Name"
              onChange={handleInputChange}
            >
              {organizations.map((org) => (
                <MenuItem key={org.id} value={org.id}>
                  {org.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Country"
            name="country"
            value={formData.country}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          {/* ...Select fields for Category, Status, and Project Progress... */}

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <label htmlFor="shapefile-upload">
              <Button
                component="span"
                variant="contained"
                startIcon={<CloudUploadIcon />}
              >
                Upload shapefile
              </Button>
            </label>
            <input
              id="shapefile-upload"
              type="file"
              accept=".zip,.shp,.shx,.dbf,.kml"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </Box>
        </>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
        }}
      >
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default AddProject;
