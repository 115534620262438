import React from "react";
import {
  AppBar,
  Button,
  Card,
  CardContent,
  Container,
  CssBaseline,
  Grid,
  Toolbar,
  Typography,
  Box,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Stack from "@mui/material/Stack";
import { LineChart } from "@mui/x-charts/LineChart";
import { SparkLineChart } from "@mui/x-charts/SparkLineChart";

const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const xLabels = [
  "Page A",
  "Page B",
  "Page C",
  "Page D",
  "Page E",
  "Page F",
  "Page G",
];

// Area chart Function
function AreaChart() {
  return (
    <LineChart
      width="1500" // Use percentage-based width for responsiveness
      height={300}
      series={[{ data: uData, label: "uv", area: true, showMark: false }]}
      xAxis={[{ scaleType: "point", data: xLabels }]}
      sx={{
        ".MuiLineElement-root": {
          display: "none",
        },
      }}
    />
  );
}
///////////////////////////////////////////////////////////////////
const uData1 = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const uData2 = [3000, 2500, 1800, 2600, 1700, 2200, 3300];
const xDataLabels = [
  "Page A",
  "Page B",
  "Page C",
  "Page D",
  "Page E",
  "Page F",
  "Page G",
];

// Area chart Function
function DualAreaChart() {
  const datasets = [
    {
      data: uData1,
      label: "uv1",
      area: true,
      showMark: false,
    },
    {
      data: uData2,
      label: "uv2",
      area: true,
      showMark: false,
    },
  ];
  return (
    <LineChart
      width="1500" // Use percentage-based width for responsiveness
      height={300}
      series={datasets}
      xAxis={[{ scaleType: "point", data: xDataLabels }]}
      sx={{
        ".MuiLineElement-root": {
          display: "none",
        },
      }}
    />
  );
}

//////////////////////////////////////////////////////////////
// BasicSparkLine Function
function BasicSparkLine() {
  return (
    <Stack direction="row" sx={{ width: "100%" }}>
      <Box sx={{ flexGrow: 1 }}>
        <SparkLineChart data={[1, 4, 2, 5, 7, 2, 4, 6]} height={100} />
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <SparkLineChart
          plotType="bar"
          data={[1, 4, 2, 5, 7, 2, 4, 6]}
          height={100}
        />
      </Box>
    </Stack>
  );
}

//main Function
const AnalyticsDashboard = () => {
  return (
    <Container maxWidth="xl">
      <CssBaseline />
      {/* Static Header */}
      <AppBar position="static" color="default">
        <Toolbar style={{ justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <AccountCircleIcon sx={{ fontSize: 40, marginRight: 2 }} />
            <div>
              <Typography
                variant="h6"
                component="div"
                sx={{ fontWeight: "bold" }}
              >
                John & Co.
              </Typography>
              <Typography variant="body2" color="textSecondary">
                John Kennedy
              </Typography>
            </div>
          </div>
          <Button
            variant="contained"
            color="primary"
            sx={{ bgcolor: "#70C29C" }}
          >
            Create Report +
          </Button>
        </Toolbar>
      </AppBar>

      {/* Top Level Grid  */}
      <Grid container spacing={4} style={{ marginTop: "20px" }}>
        {/* First Card */}
        <Grid item xs={12} sm={6} md={4}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h5" gutterBottom>
                £ 52,749.92
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Starling Bank Business Account
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Second Card */}
        <Grid item xs={12} sm={6} md={4}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h5" gutterBottom>
                £ 37,273.00
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Income Tax Estimate
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Third  Card */}
        <Grid item xs={12} sm={6} md={4}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h5" gutterBottom>
                £ 3,682.50
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Expenses
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Fouth  Card */}
        <Grid item xs={12} sm={6} md={4}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h5" gutterBottom>
                £ 3,682.50
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Expenses
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* 5th  Card */}
        <Grid item xs={12} sm={6} md={4}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h5" gutterBottom>
                £ 2,312.23
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Income Tax Estimate
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Sixth Card */}
        <Grid item xs={12} sm={6} md={4}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h5" gutterBottom>
                £ 2,312.23
              </Typography>
              <Typography variant="body2" color="textSecondary">
                VAT Tax Estimate
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* First Chart */}
      <Grid item xs={12}>
        <Card
          variant="outlined"
          style={{ marginTop: "20px", minHeight: "200px" }}
        >
          {/* Add your chart here */}
          <div className="App">
            <AreaChart />
          </div>
        </Card>
      </Grid>

      {/* Dual Area Chart */}
      <Grid item xs={12}>
        <Card
          variant="outlined"
          style={{ marginTop: "20px", minHeight: "200px" }}
        >
          {/* Add your chart here */}
          <div className="App">
            <DualAreaChart />
          </div>
        </Card>
      </Grid>

      {/* Second Chart */}
      <Grid item xs={12}>
        <Card
          variant="outlined"
          style={{ marginTop: "20px", minHeight: "200px" }}
        >
          {/* Add your chart here */}
          <div className="App">
            <BasicSparkLine />
          </div>
        </Card>
      </Grid>
    </Container>
  );
};

export default AnalyticsDashboard;
