import React, { useState } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  FormControl,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const EligibilityCheck = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [eligibilityMessage, setEligibilityMessage] = useState("");

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleCheckEligibility = async () => {
    if (!file) {
      setEligibilityMessage("Please upload a shapefile to check eligibility.");
      return;
    }

    setLoading(true);
    setEligibilityMessage("");

    const formData = new FormData();
    formData.append("shapefile", file);

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/check-eligibility/`,
        formData,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setLoading(false);
      if (response.data.eligible) {
        setEligibilityMessage(
          "The shapefile is eligible for a carbon project."
        );
      } else {
        setEligibilityMessage(
          "The shapefile is not eligible for a carbon project."
        );
      }
    } catch (error) {
      setLoading(false);
      setEligibilityMessage("Failed to check eligibility. Please try again.");
      console.error("Error checking eligibility:", error);
    }
  };

  return (
    <Box sx={{ maxWidth: 500, margin: "auto", textAlign: "center" }}>
      <Typography
        variant="h4"
        sx={{ mb: 2, fontWeight: "bold", color: "#0E3B43" }}
      >
        Check Eligibility for Carbon Project
      </Typography>
      {eligibilityMessage && (
        <Typography
          variant="body1"
          sx={{
            mb: 2,
            color: eligibilityMessage.includes("not") ? "red" : "green",
          }}
        >
          {eligibilityMessage}
        </Typography>
      )}
      <FormControl fullWidth margin="normal">
        <label htmlFor="shapefile-upload">
          <Button
            component="span"
            variant="contained"
            startIcon={<CloudUploadIcon />}
          >
            Upload shapefile
          </Button>
        </label>
        <input
          id="shapefile-upload"
          type="file"
          accept=".zip,.shp,.shx,.dbf,.kml"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </FormControl>
      <Box sx={{ mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCheckEligibility}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Check Eligibility"}
        </Button>
      </Box>
    </Box>
  );
};

export default EligibilityCheck;
