// ShapeFileDownload.js

import React from "react";
import axios from "axios";
import Chip from "@mui/material/Chip";

const ShapeFileDownload = ({ projectId }) => {
  const handleShapefileDownload = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `http://127.0.0.1:8000/api/customers/projects/${projectId}/download_shapefile/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
          responseType: "blob", // This indicates that the response is binary data
        }
      );

      if (response.data.size > 0) {
        // File present, trigger download
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "shapefile.zip"; // You can specify the filename here
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        // Display "file downloaded" message
        alert("File downloaded");
      } else {
        // No file present, display a message
        alert("No file present");
      }
    } catch (error) {
      console.error(
        "Error downloading shapefile or The shapefile may be missing:",
        error
      );
      // Handle the error here
      alert("Error downloading shapefile");
    }
  };

  return (
    <Chip
      label="Download Shapefile"
      variant="outlined"
      onClick={handleShapefileDownload}
      sx={{ ml: 1 }}
    />
  );
};

export default ShapeFileDownload;
