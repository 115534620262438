// import React from "react";
// import { MapContainer, TileLayer, ImageOverlay } from "react-leaflet";
// import "leaflet/dist/leaflet.css";

// const ImageOverlayMap = () => {
//   const minx = 30.382729707962042;
//   const miny = -3.737889897221329;
//   const maxx = 31.178187892049877;
//   const maxy = -3.345056623475862;
//   const imageBounds = [
//     [miny, minx],
//     [maxy, maxx],
//   ];

//   // Update the path to your image accordingly
//   // const imageUrl = "http://127.0.0.1:8000/static/output_image.jpeg";

//   //const imageUrl =
//   // "https://storage.cloud.google.com/jeev_demo_bucket/output_image.jpeg";
//   //   const imageUrl =
//   //     "https://storage.googleapis.com/bucket-quickstart_lively-marking-412814/output_image.jpeg";

//   const imageUrl =
//     "https://storage.googleapis.com/bucket-quickstart_lively-marking-412814/masked_output.jpg";
//   //const imageUrl =
//   //  "https://maps.lib.utexas.edu/maps/historical/newark_nj_1922.jpg";

//   return (
//     <MapContainer
//       style={{ height: "500px", width: "100%" }}
//       bounds={imageBounds}
//       zoomSnap={0.5}
//     >
//       <TileLayer
//         url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//         attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//       />
//       <ImageOverlay url={imageUrl} bounds={imageBounds} />
//     </MapContainer>
//   );
// };

// export default ImageOverlayMap;

import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import GeoRasterLayer from "georaster-layer-for-leaflet";

// Assuming you're using a bundler like Webpack, import parseGeoraster directly:
import { parseGeoraster } from "georaster";

const GeoRasterLayerWrapper = ({ url }) => {
  const map = useMap();
  const [layer, setLayer] = useState(null); // Add state to manage the layer

  useEffect(() => {
    // Only fetch and parse if a valid map and URL are available
    if (map && url) {
      fetch(url)
        .then((response) => response.arrayBuffer())
        // Use the parsed georaster to create the layer
        .then((arrayBuffer) => parseGeoraster(arrayBuffer))
        .then((georaster) => {
          const layer = new GeoRasterLayer({
            georaster,
            opacity: 0.7,
            pixelValuesToColorFn: (values) =>
              values[0] === 42 ? "#ffffff" : "#000000",
            resolution: 64,
          });
          setLayer(layer); // Update the layer state
          map.fitBounds(layer.getBounds());
        });
    }
  }, [map, url]);

  return layer && layer.addTo(map); // Conditionally render the layer
};

const ImageOverlayMap = () => {
  const url_to_geotiff_file =
    "/Users/nitishgautam/Desktop/code/jeev/jeev-platform/scripts/prototype/GIS/output_image.tif";

  return (
    <MapContainer
      center={[0, 0]}
      zoom={5}
      style={{ height: "500px", width: "100%" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <GeoRasterLayerWrapper url={url_to_geotiff_file} />
    </MapContainer>
  );
};

export default ImageOverlayMap;
